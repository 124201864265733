<template>
    <!-- {{bc}} -->
     <ModalWindow
        title="Current Balance" 
        ref="BalanceDetails" 
    >
    <p class="alert custom-alert-1 mb-4 alert-dismissible">Your current balance consists of a monthly rechargeable amount, covering both outbound and inbound traffic. Once the monthly balance is fully utilized, the top-up balance is applied to ensure your service remains uninterrupted. The top-up balance carries over from month to month and is not reset.</p>
    <table class="table table-bordered table-striped table-hover">
        <tbody>
        <tr>
            <td>
                Monthly Balance: 
            </td>
            <td>
            ${{parseInt(callcenterData?.monthly_balance/100)/100}}
            </td>
        </tr>
        <tr>
            <td>
                Topup balance: 
            </td>
            <td>
                ${{parseInt(callcenterData?.topup_balance/100)/100}}
            </td>
        </tr>
    </tbody>
    </table>
    </ModalWindow>
    <ModalWindow
        :title="modalTitle"
        ref="PaymentModal" 
        mode=""
        :showCustomButton="true"
        :customButtonCaption="nextButtonCaption"
        :onCustomButtonClick = "next"
    >
    
        <twilioSelectDID
            ref="phoneSelector" 
            v-model= "paymentParams.SelectedNumber"
            v-if="screen=='number_selection'"
        />    
        <PaymentInvoice 
            
            v-model="paymentParams.paymentSettings"
            v-if="screen=='invoice'"
            :payment_type="payment_type"
        />

        <PaymentTopup 
            v-model="topupParams"
            v-if="screen=='topup'"
            :payment_type="payment_type"
        />
        <PaymentTopupInvoice
            v-model="topupParams"
            v-if="screen=='topup-invoice'"
            payment_type="topup"
        />
    </ModalWindow>
    <keep-alive>
    <Subheader style="z-index:1000">
        
        <div id="breadcrumb" class="vue-portal-target ms-4 me-3" style="margin-top:3px;z-index:0">
            
                <ul class="navbar-nav flex-row" >
                    <li>
                        <div class="page-header">
                            <nav class="breadcrumb-one" aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li v-for="item of bc" class="breadcrumb-item d-none d-lg-block" >
                                        <a href="javascript:void(0);"
                                            :style="item.is_current == true ? 'cursor:auto' : 'color:#0045c4'"
                                            @click="openLink(item.url)"><strong>{{ item.name }}</strong></a>
                                    </li>
                                    <li class="breadcrumsb-item d-block d-lg-none">
                                        <a href="javascript:void(0);" @click="openLink(backButtonUrl)" style="color:#0045c4'" >
                                            <span style="max-height:4px">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left" data-v-5522efca=""><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg> /&nbsp</span>
                                        </a>
                                    </li>
                                    <li v-for="item of bc" class="breadcrcsumb-item d-block d-lg-none" >
                                        <a href="javascript:void(0);" v-if="item.is_current == true"
                                            :style="item.is_current == true ? 'cursor:auto' : 'color:#0045c4'"
                                            @click="openLink(item.url)"><strong>{{ item.name }}</strong></a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </li>
                </ul>
                <!-- {{backButtonUrl}} -->
                
                <ul class="navbar-nav flex-row ms-auto  d-none d-lg-block">
                    <ul class="ms-5 nav nav-pills mt-1 justify-content-center" id="rounded-pills-icon-tab" role="tablist">
                        <li class="nav-item" v-for="item of menuItems">
                            <a @click="navigate(item.endpoint)" :class="current_page==item.endpoint ? 'active' : ''" class="nav-link mb-2 text-center" id="rounded-pills-icon-home-tab" data-bs-toggle="pill" href="#rounded-pills-icon-home" role="tab" aria-controls="rounded-pills-icon-home" aria-selected="true" disabled="false">
                                <span v-html="item.icon"></span>
                                {{item.name}}
                            </a>
                        </li>
                    </ul>
                </ul> 
            
        </div>

        <!-- Mobile Menu -->
        <div class="dropdown nav-item notification-dropdown btn-group d-block d-lg-none" style="z-index:100">
                        <a href="javascript:;" id="ddlnotify" data-bs-toggle="dropdown" aria-expanded="true" class="btn dropdown-toggle btn-icon-only nav-link show">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu" data-v-5522efca=""><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                            
                        </a>
                        <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="ddlnotify" data-bs-popper="none">
                            <div  v-for="(item, index) of menuItems">
                                <li role="presentation">
                                    <a href="javascript:;" class="dropdown-item" @click="navigate(item.endpoint)">
                                        <div class="media server-log">
                                            <div class="media-aside align-self-start">
                                                <span v-html="item.icon"></span>
                                            </div>
                                            <div class="media-body">
                                                <div class="data-info">
                                                    <h6>{{item.name}}</h6>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </a>
                                </li>

                                <li role="presentation" v-if="menuItems.length != (index+1)">
                                    <hr role="separator" aria-orientation="horizontal" size="0" class="dropdown-divider">
                                </li>
                            </div>
                        </ul>
                    </div>
        
    </Subheader>
</keep-alive>
<keep-alive>
    <Subheader v-if="callcenterData && callcenterData.mode == 'production'">
        <div class="w-a-idnfo funds-spesnt">
            <p class="text-start ms-4 mt-1">
                <strong>
                    <span class="d-block d-md-none">{{phone_number.join(", ")}}</span>
                    <span class="d-none d-md-block">Phone Number: {{phone_number.join(", ")}}</span>
                </strong>
            </p>
        </div>
        <span class="ms-auto me-2 mt-1">
            <div v-if="callcenterData" class="w-a-info funds-spent">
                
                <span v-if="callcenterData.monthly_balance || callcenterData.topup_balance" class="badge badge-secondary me-2">
                    <span class="d-block d-md-none">
                        ${{ parseInt(callcenterData.monthly_balance/100)/100 + parseInt(callcenterData.topup_balance/100)/100}}
                    </span>
                    <span class="d-none d-md-block" @click="showBalanceDetails">
                        Balance: ${{ parseInt(callcenterData.monthly_balance/100)/100 + parseInt(callcenterData.topup_balance/100)/100}}
                    </span>
                </span>
                <span v-if="callcenterData.topup_allowed == false && $getRole()=='admin'" class="badge badge-secondary me-2" @click="showTopupWindow()">
                    + 
                </span>
                
                <!-- {{callcenterData}} -->
            </div>
            <!-- Balance: ${{ parseInt(callcenterData.monthly_balance/100)/100 + parseInt(callcenterData.topup_balance/100)/100}}  -->
        </span> <!-- For Bootstrap 5 -->
    
        <!-- <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="ddlnotify">
        <li>rere</li>
        </ul> -->
        <!-- <div class="row">
            <div class="col text-end inv-action">
                 -->
                    <!-- <a href="javascript:;" class="btn pay-now bdtn-outline-success">Pay Now $29.51</a></div>
            Balance: ${{ parseInt(callcenterData.monthly_balance/100)/100 + parseInt(callcenterData.topup_balance/100)/100}} 
        </div> -->
    <!-- </div> -->
    </Subheader>
</keep-alive>
    <div v-if="callcenterData && callcenterData.mode=='test'" class="alert   alert-light-danger alert-dismissible  mt-3 ms-4 me-3" role="alert">
        <div class="ms-5 me-5">
            While in testing mode, you can test the inbound functionality of your call centers by dialing <strong>+1 (858) 330-4450 </strong> and entering your assigned PIN code for tesinig, as provided below. Once you upgrade, a direct phone number will be assigned, and you will be able to dial in without PIN codes. Calls made with a PIN code are not billable but are limited to 3 minutes.
            <br><br><strong>PIN CODE: {{callcenterData.pin_code}}</strong></br>
            <div v-if="role=='admin'" class="text-end">
                <button @click="upgrade" class="btn btn-outline-secondary " style="max-height:40px">Upgrade to production</button>
            </div>
        </div>
    </div>
</template>
<script setup>
    // import { useRouter } from 'vue-router';
    import { loadStripe } from '@stripe/stripe-js' 
    const {$getCurrentMembership, $toInternationalFormat, $socket, $socketCallcenter} = useNuxtApp();
    const {$get} = useNuxtApp();


// onMounted(()=>{
//     const callcenter_id_2 = sessionStorage.getItem('callcenter_id');
//     alert(callcenter_id_2);
// })

const {  initCallcenterWebSocket, callcenterMessages } = useCallcenterWebSocket();

const initialaizeCallcenterWebSocket = () => {
    const token = sessionStorage.getItem('token');
    const account_id = sessionStorage.getItem('account_id');
    const callcenter_id = sessionStorage.getItem('callcenter_id');
    
    if (token && account_id && callcenter_id) {
        
        initCallcenterWebSocket(token, account_id, callcenter_id);
        
        $socketCallcenter.subscribeToCallcenterEvents(token, account_id, callcenter_id)
        console.log("Subcribing.....")
    } else {
        console.log('Missing token, account_id, or account_user_id for user subscription');
    }
};

// import { useCallcenterWebSocket , callcenterMessages} from '~/composables/useCallcenterWebSocket';

// const { messages: userMessages, initCallcenterWebSocket } = useCallcenterWebSocket();
// const initialaizeCallcenterWebSocket = () => {

//   const token = sessionStorage.getItem('token');
//   const account_id = sessionStorage.getItem('account_id');
//   const callcenter_id = sessionStorage.getItem('callcenter_id');

//   if (token && account_id && callcenter_id) {
//     initCallcenterWebSocket(token, account_id, callcenter_id);
    
//     $socketCallcenter.subscribeToCallcenterEvents(token, account_id, callcenter_id)
//   } else {
//     console.log('Missing token, account_id, or account_user_id for user subscription');
//   }
// };

initialaizeCallcenterWebSocket();

watch(() => callcenterMessages.value,  async (newValue) => {
    
    try {
        let message = JSON.parse(callcenterMessages.value);
        if (message.app == "new-call" && message.event=="cdr-balance"){
            callcenterData.value.topup_balance = message.data.topup_balance;
            callcenterData.value.monthly_balance = message.data.monthly_balance;
        }

    } catch(error){
        console.log(message)
    }
});


    const company        = useCompany();
    const callcenterData = useCallcenterData();
    const bc             = useBreadcrumbs();
    const backButtonUrl  = useBreadcrumbsMobileBackButton();
    const voiceList      = useVoices();
    const agentList      = useAgents();

    const moh         = useMoh();
    const modalTitle = ref("");
    const PaymentModal = ref(null);
    const phoneSelector = ref(null);
    const payment_type = ref("included_did");
    const nextButtonCaption = ref("NEXT: Payment details");
    const paymentParams = ref({});
    const screen = ref("number_selection");
    const backendUrl = '/api/protected/stripe/session'
    const topupBackendUrl = '/api/protected/stripe/session.payment'
    const loading = ref(false);
    const phoneNumber = usePhoneNumber();
    const phone_number = ref([]);
    const topupParams = ref({topup:20})
    const order = useAgentOrder();

    const BalanceDetails = ref()

    const showTopupWindow = () => {
        // alert("topup")
        screen.value='topup';
        const nextButtonCaption = ref("NEXT: Payment details");
        PaymentModal.value.openModal();
    }

    const showBalanceDetails = () => {
        if (BalanceDetails.value) {
            BalanceDetails.value.openModal();
        } else {
            console.error('BalanceDetails ref is null.');
        }
    };

    const sortDataByOrder = (data, order) => {
        const orderMap = new Map(order.map((id, index) => [id, index]));
        return data.sort((a, b) => (orderMap.get(a.callcenter_user._id) ?? Infinity) - (orderMap.get(b.callcenter_user._id) ?? Infinity));
    };
    const getAgents = async () => {
        if (agentList.value.length == 0){
            let response = await $get(`/api/protected/agent`);
            const savedOrder = localStorage.getItem(`agentOrder_${callcenter_id}`);
        
            if (savedOrder){
                order.value =  JSON.parse(savedOrder);
            } else {
                order.value =  []
            }
            
            agentList.value = sortDataByOrder(response.data, order.value);
        }
    }

    getAgents();
        

    const menuItems = ref([ 
                {"name": "Agents", endpoint:"agents", "icon": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>' },
                {"name": "Hotlines", endpoint:"hotlines", icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevrons-down" data-v-5522efca=""><polyline data-v-f950d9a7="" points="7 13 12 18 17 13"></polyline><polyline data-v-f950d9a7="" points="7 6 12 11 17 6"></polyline></svg>'},
                {"name": "Monitoring", endpoint:"monitoring", icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-monitor" data-v-5522efca=""><rect data-v-f950d9a7="" x="2" y="3" width="20" height="14" rx="2" ry="2"></rect><line data-v-f950d9a7="" x1="8" y1="21" x2="16" y2="21"></line><line data-v-f950d9a7="" x1="12" y1="17" x2="12" y2="21"></line></svg>'},
                {"name": "Calls", endpoint:"calls", icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text" data-v-5522efca=""><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>'},
                {"name": "Settings", endpoint:"settings", icon: '<svg data-v-f950d9a7="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings"><circle data-v-f950d9a7="" cx="12" cy="12" r="3"></circle><path data-v-f950d9a7="" d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>'},
    ]);

    const attachables = useAttachables();
    const callcenterHotlines = useCallcenterHotlines();
    const callcenterOriginalHotlines = useCallcenterOriginalHotlines();
    const callcenterLanguages = useCallcenterLanguages();
    const originalCallcenterLanguages = useCallcenterOriginalLanguages();


    const regions = useRegions();
    const route = useRoute();
    

    const callcenter_id = sessionStorage.getItem('callcenter_id');
    const country = sessionStorage.getItem('country');


    let lbData = await $get(`/api/protected/callcenter-languages/${callcenter_id}`);
    callcenterLanguages.value = lbData.data;
    originalCallcenterLanguages.value = JSON.parse(JSON.stringify(lbData.data));

    const current_page = ref();
    // router.replace({ path: `/${tab}`, query: { active: tab } });

    const openLink = (url) => {
        // alert(url);
        navigateTo(url);
        // route.push(url)
    }
    
    const navigate = (section) => {
        let callcenter_id = sessionStorage.getItem('callcenter_id');
        navigateTo(`/company/call-centers/${callcenter_id}/${section}`);
    }

    let role = ref("");
    onMounted(async ()=>{
        const currentTab = route.path;
        const parts = currentTab.split('/');
        current_page.value = parts[parts.length - 1];
        
        let account_id = sessionStorage.getItem("account_id");
        let token = sessionStorage.getItem("token");
        role.value = $getCurrentMembership(token, account_id).role;


        // Getting Music On Hold for ivr. Cached in localStorage
        let mohUrl = '/api/protected/moh';
        let mohData = [];
        let cached_moh = localStorage.getItem(`scc_${mohUrl}`);
        if (cached_moh == null){
            let response = await $get(mohUrl);
            mohData = response.data;
            localStorage.setItem(`scc_${mohUrl}`,JSON.stringify(response.data));
        } else {
            mohData = JSON.parse(cached_moh);
        }
        
        moh.value = mohData;

        {
            let response = await $get(`/api/protected/agent/attachables`);
            attachables.value = response.data;
        }

        const getVoices = async () => {
            if (process.client){
                let response = await $get(`/api/protected/language/voices`);
                let voices = {};
                for (let voice of response.data){
                    voices[voice.voice] = voice.name;
                }
                voiceList.value = voices;
            }
        }
        getVoices();

        // if (callcenterHotlines.value.length == 0) 
        {
            let response = await $get(`/api/protected/callcenter-hotlines`);
            callcenterHotlines.value = response.data;
        }

        {
            // phoneNumber - store -> {}
            let phoneNumbers;
            if (callcenter_id in phoneNumber.value){
                phoneNumbers = phoneNumber.value[callcenter_id];
            } else {
                phoneNumbers = await $get(`/api/protected/phone-number`);
                phoneNumber.value[callcenter_id] = phoneNumbers.data;
                phoneNumbers = phoneNumbers.data;

            } 

            phone_number.value = [];
            for (let item of phoneNumbers ){
                phone_number.value.push($toInternationalFormat(item.phone_number));
            }
            
        }

        {
            let regionUrl = `/api/admin/protected/failover-groups/${country}`;
            let regionData = [];
            let cached_region = localStorage.getItem(`scc_${regionUrl}`);
            if (cached_region == null){
                let response = await $get(regionUrl);
                regionData = response.data;
                
                localStorage.setItem(`scc_${regionUrl}`,JSON.stringify(response.data));
            } else {
                regionData = JSON.parse(cached_region);
            }
            regions.value = regionData;
        }
        
        {
            let response = await $get(`/api/protected/callcenter/${callcenter_id}`);
            callcenterData.value = JSON.parse(JSON.stringify(response.data));
        }

        {
            let hlData = await $get(`/api/protected/callcenter-hotlines`);
            callcenterHotlines.value =  JSON.parse(JSON.stringify(hlData.data));
            callcenterOriginalHotlines.value =JSON.parse(JSON.stringify(hlData.data));
        }
    });

    const upgrade = () => {
        modalTitle.value = "Upgrade account to Production";
        paymentParams.value = {paymentSettings:{allowed:true, subscription:20, topup: 30, service:"included_did"}};
        PaymentModal.value.openModal();
        payment_type.value="included_did";
        phoneSelector.value.getRandomNumber();
    }

    const next = async () => {


    
    
    // alert(screen.value)
    switch (screen.value){
        case "number_selection":
            screen.value = "invoice";
            nextButtonCaption.value = "NEXT: Checkout"
        break;

        case "invoice":
            // nextButtonCaption.value = "NEXT: Checkout"
            sessionStorage.setItem("selectedDID", paymentParams.value.SelectedNumber.phoneNumber);
            sessionStorage.setItem("topupAllowed", paymentParams.value.paymentSettings.allowed);
            sessionStorage.setItem("topupAmount", paymentParams.value.paymentSettings.topup);
            startCheckout();
        break;

        case "topup":
            screen.value = "topup-invoice";
            nextButtonCaption.value = "NEXT: Checkout";
        break;
        case "topup-invoice":
        startTopupCheckout();
        break;
    }
}
const startTopupCheckout = async () => {
    loading.value = true
    let url = topupBackendUrl+`?service=topup&amount=${topupParams.value.topup*100}`;
    const response = await $get(url);
    const sessionId = await response.sessionId;
    const stripe = await loadStripe('pk_test_51NIPLiIHvM6IbKomtCvHiQcP1TunrBOsSLrCovQGd9jSLRZsQt4ebxKduGByjWZ9Ga2xFJqOD2UrXKsZtVY1yj7q000DRYg1cK')

    // Redirect to the Stripe Checkout page using the sessionId
    const { error } = await stripe.redirectToCheckout({ sessionId })

    if (error) {
        console.error('Stripe Checkout error:', error)
        loading.value = false
    }

}

const startCheckout = async () => {
try {
  loading.value = true
  
  let url = backendUrl;
  let topup_allowed = paymentParams.value.paymentSettings.allowed;
  if (topup_allowed ){
    url+= "?topup=true&amount="+paymentParams.value.paymentSettings.topup+"&service="+paymentParams.value.paymentSettings.service;
  } else {
    url+= "?topup=false&service="+paymentParams.value.paymentSettings.service;
  }
  // Fetch the checkout session from the backend
  const response = await $get(url)

  const sessionId = await response.sessionId;

  // Load Stripe.js with your publishable key
  const stripe = await loadStripe('pk_test_51NIPLiIHvM6IbKomtCvHiQcP1TunrBOsSLrCovQGd9jSLRZsQt4ebxKduGByjWZ9Ga2xFJqOD2UrXKsZtVY1yj7q000DRYg1cK')

  // Redirect to the Stripe Checkout page using the sessionId
  const { error } = await stripe.redirectToCheckout({ sessionId })

  if (error) {
    console.error('Stripe Checkout error:', error)
    loading.value = false
  }
} catch (error) {
  console.error('Checkout error:', error)
  loading.value = false
}
}

// // // Function to subscribe to call center events
// function subscribeCallcenter() {
//     const token = sessionStorage.getItem('token'); // Ensure token is stored in sessionStorage
//     const account_id = sessionStorage.getItem('account_id');
//     const callcenter_id = sessionStorage.getItem('callcenter_id');

//     if (token && account_id && callcenter_id) {
//         $socket.subscribeToCallcenterEvents(token, account_id, callcenter_id);
//     } else {
//         console.log('Missing token, account_id, or callcenter_id for subscribing to call center events');
//     }
// }

//     function initializeSocket() {
//         // subscribeCallcenter();
//         $socket.init((error) => {
//             if (error) {
//             console.error('***************   Error initializing WebSocket:', error);
               
//         } else {
            
//             console.log(' ***************   WebSocket initialized successfully');
//             // subscribeUser();
            
//               subscribeCallcenter()
//             }
//         });
//     }
//     initializeSocket();
   
    
</script>

<style>
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        color: var(--bs-nav-pills-link-active-color);
        background-color: #0350c2;
    }
    .nav-pills .nav-link {
        color: #3b3f5c;
    }
    .navbar .navbar-item .nav-item.dropdown.notification-dropdown .nav-link svg {
        color: rgb(37, 97, 208);
        width: 22px;
        height: 22px;
    }
    .navbar .navbar-item .nav-item.dropdown.notification-dropdown .nav-link svg {
    color: rgb(37, 97, 208);
    width: 22px;
    height: 22px;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .media.server-log svg {
    color: #004d96;
    fill: rgb(0 63 150 / 37%);
}

</style>