<template>
    
  <ModalWindow
    :title="modalTitle"
    ref="contactForm"
    :showCreateButton="showCreateButton"
    :onCreate="createContact"
  >
  <div v-if="isCreated == true" class="mt-3">
    <h3 class="text-center">Contact was created successfully!</h3>  
    <div class="row mt-5 mb-5">
        <div class="col-6 text-center">
          <button class="btn btn-outline-primary btn-lg"  style="min-height:80px" @click="openCustomerCard">Open Customer Card</button>
        </div>
        <div class="col-6 text-center">
          <button class="btn btn-outline-dark btn-lg" style="min-height:80px" @click="openCustomerCard">Close window</button>
        </div>
      </div>
  </div>
    <div v-if="isCreated == false">
      <div class="row" v-if="accountSettings.servedTypes == 'all'">
        <div class="col-12 mb-5">
          <label>Select type of the Customer</label>
          <SelectBox v-model="object.accountData.type" :options="[{name:'Corporate Contact',value:'corporate'}, {name:'Individual Contact',value:'individual'}]" />
        </div>
      </div>

      <div v-if="object.accountData.type == 'individual' || object.accountData.type == 'all'">
        <crmCompanyForm v-if="object" v-model="object.accountData" />
      </div>

      <div v-if="object.accountData.type == 'corporate' || object.accountData.type == 'all'">
        <crmContactForm v-model="object.contactData" :showCallcenters="true" />        
        <h5 class="mt-5"> Company Information: </h5>
        <div class="row mt-4">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <label>Select to add the contact to an existing company or create a new one.</label>
            <SelectBox v-model="object.accountData.isNew" :options="[{name: 'Create new company', value:true}, {name: 'Attach to Exisitng Company', value:false} ]" />
          </div>
        </div>
        <div class="row mt-4" v-if="object.accountData.isNew==true">
          <crmCompanyForm  v-model="object.accountData" />
        </div>

        <div class="row mt-4" v-if="object.accountData.isNew==false">
          <label>Select the company from the list.</label>
          <SelectBox v-model="object.accountData.account_id" :options="crmCompanyList" />
        </div>

    </div>

    </div>
  </ModalWindow>
  
</template>

<script setup>
  const { $post, $get, $toNationalFormat,$parseJwt, $customerFormValidation, $contactFormValidation, $swal } = useNuxtApp();
  const contactForm = ref(null);
  const modalTitle = ref("Create New Contact");
  const contactNotification = useNewContactNotification();
  const showCallcenters = ref(true);
  const object = ref({accountData:{type:""}, contactData:{}} );
  const showCreateButton = ref(true);
  const showCompanyForm = ref(false);
  const crmCompanyList = useCRMCompanyList();
  const isCreated = ref(false);
  const accountId = ref("");
  // const crmSettings = useCRMAccountSettings();
  const accountSettings = useCRMAccountSettings()
  const contactSettings = useCRMContactSettings();
  const errors = ref([])
  const phoneNumberStored = ref("");
     

  const showCreateContactForm = (phoneNumber = null, callcenter_id=null) => {
    phoneNumberStored.value = phoneNumber;
    if (accountSettings.value.servedTypes == 'individual'){
      object.value.accountData = { phones: [{ phone: {number:phoneNumber}, hint: "" }], customFields:{} };
      object.value.accountData.type = accountSettings.value.servedTypes;
      
      for (let field of accountSettings.value.customFields){
        if (field.default_value){
          object.value.accountData.customFields[field._id] = field.default_value;
        } else {
          object.value.accountData.customFields[field._id] = "";
        }
      }
    }

    if (accountSettings.value.servedTypes == 'corporate'){
      object.value.contactData = { phones: [{ phone: {number: phoneNumber}, hint: "" }], customFields:{} };
      object.value.accountData = { phones: [{ phone: {number:""}, hint: "" }], customFields:{}, account: { isNew: false } };
      object.value.accountData.type = accountSettings.value.servedTypes;
    }
    contactForm.value.openModal();
  };

  
  
  watch(() => object.value.accountData.type, (newValue) => {
    if (accountSettings.value.servedTypes != 'all') return;
    if (newValue == "corporate") {
        object.value.contactData = { phones: [{ phone: {number: phoneNumberStored.value}, hint: "" }], customFields:{} };
        object.value.accountData = { phones: [{ phone: {number:""}, hint: "" }], customFields:{}, account: { isNew: false } };
        object.value.accountData.type = newValue;
      // showCompanyForm.value = true;
      // object.value.account.isNew = showCompanyForm.value;
    } 
    if (newValue == "individual") {
      object.value.accountData = { phones: [{ phone: {number:phoneNumberStored.value}, hint: "" }], customFields:[] };
      object.value.accountData.type = newValue;
      
      for (let field of accountSettings.value.customFields){
        if (field.default_value){
          object.value.accountData.customFields[field._id] = field.default_value;
        } else {
          object.value.accountData.customFields[field._id] = "";
        }
      }
      // showCompanyForm.value = false;
      // object.value.account.isNew = showCompanyForm.value;
    }
  });

  watch(() => contactNotification.value, (newValue) => {
    if (newValue) {
      if (Object.keys(newValue).length > 0) {
        isCreated.value = false;
        showCreateContactForm(newValue.phone, newValue.callcenter_id);
      }
    }
  });

  const createContact = async () => {
    
    if (object.value.accountData.type == ""){
      $swal.fire({
                // title: "Cannot create customer record because of validation error(s)",
                html: "Please select type of the customer",
                icon: "error",
            });
        
        return
    }
    if (object.value.accountData.type == "individual"){
        errors.value = $customerFormValidation(object.value.accountData, accountSettings.value);
    }

    if (object.value.accountData.type == "corporate"){
      
      errors.value = $contactFormValidation(object.value.contactData, contactSettings.value);
      
      if (typeof object.value.accountData.isNew == "undefined") { 
        errors.value.push("Please select if you want to create a new company or attach to existing one");
        
        $swal.fire({
                title: "Cannot create customer record because of validation error(s)",
                html: `-${errors.value.join("<br> -")}`,
                icon: "error",
            });
        
        return
      }
      if ( object.value.accountData.isNew == true) { 
        let accountErrors = $customerFormValidation(object.value.accountData, accountSettings.value);
        if (accountErrors.length > 0) {
          errors.value = errors.value.concat(accountErrors);  
        }
      }

      if ( object.value.accountData.isNew == false) { 
        
        if (typeof object.value.accountData.account_id == "undefined") {
          errors.value.push("Please select company from the list");
        }
      }

    }
        
        if (errors.value.length > 0) {
            $swal.fire({
                title: "Cannot create customer record because of validation error(s)",
                html: `-${errors.value.join("<br> -")}`,
                icon: "error",
            });
        
            return
        }

        if (errors.value.length > 0) {
            $swal.fire({
                title: "Cannot update customer record because of validation error(s)",
                html: `-${errors.value.join("<br> -")}`,
                icon: "error",
            });
            return
        }

    try {
      let response = await $post(`/api/protected/crm/contact`, object.value);
      isCreated.value = true;
      showCreateButton.value = false;
      modalTitle.value = "";
      // if (response.data.account.isNew) {
      //   crmCompanyList.value.push({ name: response.data.account.name, value: response.data.account._id.toString() });
      // }
       accountId.value = response.data.account_id;
      //  isCreated.value = true;
    } catch (error) {
      // alert(error.message);
      $swal.fire({
        title: "Error",
        text: error.message,
        icon: "error",
      });
    }
  };

  const openCustomerCard = () => {
      let link  = "/company/customers/"+accountId.value;
      contactForm.value.closeModal();
      navigateTo(link);
      
      hideToast();
  }

  if (crmCompanyList.value.length == 0) {
    let response = await $get(`/api/protected/crm/company/list`);
    crmCompanyList.value = response.data;
    isCreated.value = false;
  }

  const createCompany = () => {
    showCompanyForm.value = true;
    object.value.account.isNew = showCompanyForm.value;
  };

  const cancelCreateCompany = () => {
    showCompanyForm.value = false;
    object.value.account.isNew = showCompanyForm.value;
  };
</script>