export const useCRMIssueTrackerSettings = () => useState("CRMIssueTrackerSettings", () => {
    return reactive({
        customFields:[], 
        "showCallcenterInList": false, 
        "showCreatedByInList": false, 
        "showCreatedTimeInList": false,
        "allowDelete": true,
        "allowEdit": true
    
    });
});