<template>
    <div class="row">
      
      <div class="col-4">
        <label for="firstName" class="form-label">First Name<span class="text-danger">*</span></label>
        <input
          type="text"
          class="form-control"
          id="firstName"
          v-model="modelValue.firstName"
          placeholder="Enter first name"
          required
        />
      </div>
      <div class="col-4">
        <label for="lastName" class="form-label">Last Name<span class="text-danger">*</span></label>
        <input
          type="text"
          class="form-control"
          id="lastName"
          v-model="modelValue.lastName"
          placeholder="Enter last name"
          required
        />
      </div>
      <div class="col-2">
        <label for="title" class="form-label">Title</label>
        <selectBox 
            v-model="modelValue.title" 
            :options="[{ value: '', name: '-' }, { value: 'Mr', name: 'Mr.' }, { value: 'Ms', name: 'Ms.' }, { value: 'Mrs', name: 'Mrs.' }, { value: 'Dr', name: 'Dr.' }, { value: 'Prof', name: 'Prof.' }, { value: 'Rev', name: 'Rev.' }]" 
            />
        
      </div>
      <div class="col-2">
        <label for="suffix" class="form-label">Suffix</label>
        <selectBox 
            v-model="modelValue.suffix"
            :options="[{ value: '', name: '-' }, { value: 'Jr', name: 'Jr.' }, { value: 'Sr', name: 'Sr.' }, { value: 'II', name: 'II' }, { value: 'III', name: 'III' }, { value: 'IV', name: 'IV' }]"  
        />
        
      </div>
    </div>
  </template>
  
  <script setup>
  import { toRefs, reactive } from 'vue';
  
  // Props to handle v-model
  
  const props = defineProps({
    modelValue: {
      type: Object,
      required: true,
      default: () => ({
        title: '',
        firstName: '',
        lastName: '',
        suffix: '',
      }),
    },
  });
  
  const emit = defineEmits(['update:modelValue']);
  const localModel = ref({});
  
  watch(
    localModel,
    (newValue) => {
      emit('update:modelValue', { ...newValue });
    },
    { deep: true }
  );

  watch(
    () => props.modelValue,
    (newValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(localModel.value)) {
            localModel.value = { ...newValue };
        }
    //   localModel.value = { ...newValue };
    },
    { deep: true, immediate: true }
  );
  // Emit for v-model binding
//   defineEmits(['update:modelValue']);
  </script>
  
  <style scoped>
  /* Add any custom styles here */
  </style>