<template>
    <div class="select2">
        <multiselect v-model="selectedLanguage" :options="languages" placeholder="" selected-label="" select-label="" :allow-empty="false" :can-deselect="false"
         :disabled="props.disabled" deselect-label="" object="false" track-by="value" label="label" class="multiselect-blue">
        </multiselect>
    </div>
    <!-- <div v-if="$isMobile()">
        <select v-model="selectedLanguage">
                <option v-for="value of languages" :value="value" :key="value.value">
                    {{ value.label }}
                </option>
        </select>
    </div> -->

    
</template>

<script setup>

import Multiselect from '@suadelabs/vue3-multiselect';
import '@suadelabs/vue3-multiselect/dist/vue3-multiselect.css';
import '~/assets/css/select2.css';
import { useI18n } from "vue-i18n";
import { ref, getCurrentInstance } from 'vue';

const instance = getCurrentInstance();

const props = defineProps({
    modelValue: { type: String, default: "" }, // en
    country: { type: String, default: null },
    exclude: { type: Array, default: [] },
    disabled: { type: Boolean, default: false },
    onChange: Function
});

const i18n = useI18n();
let currentValue = ref(props.modelValue)

const selectedLanguage = ref({});
onMounted(() => {
    selectedLanguage.value = { value: props.modelValue, label: i18n.t(`lang-${props.modelValue}`) }
})


watch(() => props.modelValue, async (newValue) => {
    selectedLanguage.value = { value: newValue, label: i18n.t(`lang-${newValue}`) }
}, { deep: true  })

const languageArray = useSupportedLanguages();

const languages = computed(() => {
    let arr = [];
    for (let language of languageArray.value) {
        let object = { value: language, label: i18n.t(`lang-${language}`) };
        if (props.exclude.includes(language)) {
            object['$isDisabled'] = true;
            object.label = i18n.t(`lang-${language}`);
        }
        arr.push(object);
    }
    return arr;
})

watch(() => selectedLanguage.value, async (newValue) => {
    instance.emit('update:modelValue', newValue.value);
    if (typeof props.onChange === 'function') {
            props.onChange(newValue.value);
    }

}, { deep: true   })

</script>


