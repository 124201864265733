<template>
  <div class="row g-3 mt-3">
    <div class="col-md-6">
      <label for="inputStreet" class="form-label">Street Address<span class="text-danger">*</span></label>
      <input v-model="address.street" type="text" class="form-control"  placeholder="">
      <small class="text-muted">e.g., house number and street</small>
    </div>
    <div class="col-md-6">
      <label for="inputStreet" class="form-label">Street Address (line 2)</label>
      <input v-model="address.street2" type="text" class="form-control"  placeholder="">
      <small class="text-muted">e.g., apartment, suite, unit, building, floor - optional</small>
    </div>
    

    <div class="col-md-3">
      <label for="inputCity" class="form-label">Country<span class="text-danger">*</span></label>
      <selectBox :preselectFirst="true" v-model="address.country"  @change="address.state = ''" :options="[{name:'United States', value:'US'}, {name:'Canada', value:'CA'}]" />
    </div>
    
    <div class="col-md-4" >
      <label for="inputState" class="form-label">State<span class="text-danger">*</span></label>
      <selectBox :disabled="!address.country" v-model="address.state" :options="states[address.country]" />
    </div>

    <div class="col-md-3">
      <label for="inputCity" class="form-label">City<span class="text-danger">*</span></label>
      <input  v-model="address.city" type="text" class="form-control" id="inputCity" placeholder="">
    </div>
    
    
    <div class="col-md-2">
      <label for="inputZip" class="form-label">ZIP Code<span class="text-danger">*</span></label>
      <input v-model="address.zip" type="text" class="form-control" id="inputZip" placeholder="">
    </div>
  </div>
</template>

<style scoped>
  .form-control {
    height: 54px;
    /* font-size: 13px; */
  }
</style>

<script setup lang="js">
  import { useI18n } from "vue-i18n";
  const i18n = useI18n();
  const emit = defineEmits(["update:modelValue"]);

  const account = ref({});
  const stateList = useStateList();
  let states = ref({"US":[], "CA":[]})
  for (let key in stateList.value ){
    for (let item of stateList.value[key] ){
        states.value[key].push({value: item, name: i18n.t(`${key}.${item}`)})
    } 
  }
  states.value.US.shift();

  const props = defineProps({
    modelValue: { type: Object, default: () => ({}) },
  });

  const address = ref(props.modelValue);
  
  watch( () => address.value, (newValue) => {
    // emit('update:modelValue', newValue);
  });

  watch( () => props.modelValue, (newValue, oldValue) => {
    if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
      address.value = newValue;
    }
    
   
  }, { deep: true, immediate: true });
  
  
        
    
</script>