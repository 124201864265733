<template>
    <div class="row">
      <!-- Year Selector -->
      <div class="col-4">
        <small for="year">Year</small>
        <SelectBox
          :options="yearOptions"
          v-model="selectedYear"
          style="width: 100%;"
          :disabled="false"
        />
      </div>
  
      <!-- Month Selector -->
      <div class="col-4">
        <small for="month">Month</small>
        <SelectBox
          :options="monthOptions"
          v-model="selectedMonth"
          style="width: 100%;"
          :disabled="!selectedYear"
        />
      </div>
  
      <!-- Day Selector -->
      <div class="col-4">
        <small for="day">Day</small>
        <SelectBox
          :options="dayOptions"
          v-model="selectedDay"
          style="width: 100%;"
          :disabled="!selectedMonth"
        />
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, watch } from "vue";
  import SelectBox from "./SelectBox.vue";
  
  // Props and Emits
  const props = defineProps({
    modelValue: {
      type: String,
      default: "",
    },
  });
  
  const emit = defineEmits(["update:modelValue"]);
  
  // Data and Options
  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from({ length: 101 }, (_, i) => ({
    value: currentYear - i,
    name: (currentYear - i).toString(),
  }));
  const monthOptions = Array.from({ length: 12 }, (_, i) => ({
    value: i + 1,
    name: (i + 1).toString().padStart(2, "0"),
  }));
  
  const dayOptions = ref([]);
  const selectedYear = ref(null);
  const selectedMonth = ref(null);
  const selectedDay = ref(null);
  
  // Watchers
  watch(
    () => selectedYear.value,
    () => {
      updateDays();
      updateModel();
    }
  );
  
  watch(
    () => selectedMonth.value,
    () => {
      updateDays();
      updateModel();
    }
  );
  
  watch(
    () => selectedDay.value,
    () => updateModel()
  );
  
  watch(
    () => props.modelValue,
    (value) => {
      if (value && !isNaN(Date.parse(value))) {
        const date = new Date(value); // Parse ISO date
        selectedYear.value = date.getUTCFullYear(); // Use UTC methods for consistency
        selectedMonth.value = date.getUTCMonth() + 1; // Months are zero-based
        selectedDay.value = date.getUTCDate(); // Get the day
        updateDays();
      } else {
        console.warn("Invalid or empty modelValue provided:", value);
        selectedYear.value = null;
        selectedMonth.value = null;
        selectedDay.value = null;
        dayOptions.value = [];
      }
    },
    { immediate: true }
  );
  
  // Methods
  function updateDays() {
    if (selectedYear.value && selectedMonth.value) {
      const daysInMonth = new Date(
        selectedYear.value,
        selectedMonth.value,
        0
      ).getUTCDate();
  
      dayOptions.value = Array.from({ length: daysInMonth }, (_, i) => ({
        value: i + 1,
        name: (i + 1).toString().padStart(2, "0"),
      }));
  
      // Reset day if it's out of range
      if (selectedDay.value > daysInMonth) {
        selectedDay.value = null;
      }
    } else {
      dayOptions.value = [];
      selectedDay.value = null;
    }
  }
  
  function updateModel() {
    const year = selectedYear.value;
    const month = selectedMonth.value
      ? String(selectedMonth.value).padStart(2, "0")
      : "01";
    const day = selectedDay.value
      ? String(selectedDay.value).padStart(2, "0")
      : "01";
  
    if (year && month && selectedDay.value) {
      const isoDate = new Date(Date.UTC(year, selectedMonth.value - 1, selectedDay.value)).toISOString();
      emit("update:modelValue", isoDate);
    } else if (year && month) {
      // Emit partial date if only year and month are selected
      const partialISODate = `${year}-${month}-${day}T00:00:00.000Z`;
      emit("update:modelValue", partialISODate);
    } else {
      // Emit null if the date is incomplete
      emit("update:modelValue", null);
    }
  }
  </script>
  
  <style scoped>
  .row {
    display: flex;
    gap: 1rem;
  }
  
  .col-4 {
    flex: 1;
  }
  
  small {
    display: block;
    margin-bottom: 0.25rem;
    font-weight: bold;
  }
  </style>
  