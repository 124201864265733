// This is a specific plugin that handles multiple accounts and all headers, tokens to work with our DDB/RestAPI
export default defineNuxtPlugin(() => {
    const parseJwt = (token) => {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
      };

    const  getCurrentMembership =() => {
      const token = sessionStorage.getItem("token");
      const account_id = sessionStorage.getItem("account_id");
      if (token) {
        // Use the provided parseJwt function
        const parsedToken = parseJwt(token);
        for (let item of parsedToken.membership){
          if (item.account_id == account_id){
            return item;
          }
        }
      }
      return null;
    }
    
    
    return {
        
      provide: {
        parseJwt,
        getCurrentMembership,
        getRole(){
          let object = getCurrentMembership();
          return object.role;
        },
        getCountry(){
          let object = getCurrentMembership();
          return object.country;
        },
        getCallCentersAsList(){
          let object = getCurrentMembership();
          let returnArray = [];
          for (let item of object.subroles){
            returnArray.push({name:item.name, value:item.callcenter_id});
          }
          return returnArray;
        },
        generateUniqueId() {
          return 'id' + Date.now() + '' + Math.floor(Math.random() * 1e9);
        },
        
        isTokenExpired(token){
          if (token){
            const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
            return parseJwt(token).exp < currentTime;
          }
        },
        toId(str){
          return str.toLowerCase().replace(/[^a-z0-9]/g, '_');
        },

        translate: async (text, language) => {
          const { $post } = useNuxtApp();
          let translation = await $post(`/api/protected/services/translate`, {
            text,
            language
          });
          return translation;
        },
        translateSchedule: async (scheduleExplaination) => {
          const { $post } = useNuxtApp();
          let response = await $post(`/api/protected/services/schedule-translate`, { text: scheduleExplaination });
          return response;
        },
        prepareSchedule: async (schedule) => {
          function doubleDigit(d) {
            return d > 9 ? d : "0" + d;
          }
  
          let text = "";
  
          for (let day in schedule) {
            if (schedule[day].active) {
              text += `${day}: Open from ${doubleDigit(schedule[day].start.hours)}:${doubleDigit(schedule[day].start.minutes)} to ${doubleDigit(schedule[day].end.hours)}:${doubleDigit(schedule[day].end.minutes)} `;
              if (schedule[day].no_lunch) {
                text += " no lunch break\n ";
              } else {
                text += ` lunch from ${doubleDigit(schedule[day].break_start.hours)}:${doubleDigit(schedule[day].break_start.minutes)} to ${doubleDigit(schedule[day].break_end.hours)}:${doubleDigit(schedule[day].break_end.minutes)}  \n`;
              }
            } else {
              text += `${day}: closed\n`;
            }
          }
          return text;
        },
        prepareScheduleArray: async (schedule) => {
          function doubleDigit(d) {
            return d > 9 ? d : "0" + d;
          }
  
          let result = [];
  
          for (let day in schedule) {
            if (schedule[day].active) {
              let text = `Open from ${doubleDigit(schedule[day].start.hours)}:${doubleDigit(schedule[day].start.minutes)} to ${doubleDigit(schedule[day].end.hours)}:${doubleDigit(schedule[day].end.minutes)} `;
              if (schedule[day].no_lunch) {
                text += " no lunch break\n ";
              } else {
                text += ` lunch from ${doubleDigit(schedule[day].break_start.hours)}:${doubleDigit(schedule[day].break_start.minutes)} to ${doubleDigit(schedule[day].break_end.hours)}:${doubleDigit(schedule[day].break_end.minutes)}  \n`;
              }
              result.push({ day, text });
            } else {
              result.push({ day, text: 'closed' });
            }
          }
          return result;
        },
        tts: async (text, languageCode, voice, filename = "") => {
          const { $post } = useNuxtApp();
          let body = { text, languageCode, voice };
          if (filename !== "") body.filename = filename;
          let tts_response = await $post(`/api/protected/services/tts`, body);
          return tts_response.data;
        },
        toHumanDate: (ts,timeStyle="short") => {
          const date = new Date(ts * 1000)
          const humanFriendlyDate = date.toLocaleString(undefined, {
              dateStyle: 'medium', // You can change this to 'full', 'long', 'short' as per your preference
              timeStyle, // Includes the time in the format
          });
          return humanFriendlyDate
        },
        toHumanDateFromISO: (isoDate) => {
          const date = new Date(isoDate); // Convert ISO date string to Date object
          const humanFriendlyDate = date.toLocaleString(undefined, {
            dateStyle: 'medium', // You can use 'full', 'long', 'short', etc.
            timeStyle: 'short', // Includes the time
          });
          return humanFriendlyDate;
        },
        toHumanDateOnlyFromISO: (isoDate) => {
          const date = new Date(isoDate); // Convert ISO date string to Date object
          const humanFriendlyDate = date.toLocaleString(undefined, {
            dateStyle: 'medium', // You can use 'full', 'long', 'short', etc.
            
          });
          return humanFriendlyDate;
        },
        
        shortHumanFriendlyDuration: (seconds) => {
          // Calculate hours, minutes, and seconds
         const hours = Math.floor(seconds / 3600);
         const minutes = Math.floor((seconds % 3600) / 60);
         const remainingSeconds = seconds % 60;
  
         // Build the short format (e.g., "1h 2m 5s")
         const hoursDisplay = hours > 0 ? `${hours}h` : '';
         const minutesDisplay = minutes > 0 ? `${minutes}m` : '';
         const secondsDisplay = remainingSeconds > 0 ? `${remainingSeconds}s` : '';
         console.log([hoursDisplay, minutesDisplay, secondsDisplay])
         // Combine into a string, filtering out empty parts
         if (!hoursDisplay && !minutesDisplay && !secondsDisplay){
             return "-"
         } else {
             return [hoursDisplay, minutesDisplay, secondsDisplay].filter(Boolean).join(' ');
         }
        },
        contactFormValidation: (form, settings) => {
          function toId(str){
            return str.toLowerCase().replace(/[^a-z0-9]/g, '_');
          };
          const errors = [];
          if (!form.firstName || form.firstName.trim() === "") {
            errors.push("First name is required");
          }
          if (!form.lastName || form.lastName.trim() === "") {
            errors.push("Last name is required");
          }
           
          if (settings.phone.required && settings.phone.show) {
            
            let phoneIsValid = true;
            for (let phoneObject of form.phones) {
              if (!phoneObject.phone.number || phoneObject.phone.number.trim() === "") {
                errors.push("Phone number can't be empty");
                phoneIsValid = false;
              } else {
                if (!phoneObject.phone.isValid) {
                  phoneIsValid = false;
                  errors.push("Phone number is not a valid phone number");
                }
              }
            }
            //  errors.push(" is required");
          }
          

          if (settings.gender.required && settings.gender.show ) {
            if (!form.genderData || form.genderData.selectedGender.trim() === "") {
              errors.push("Gender is required");
            }
          }
          
          if (settings.position.required && settings.position.show) {
            if (!form.position || form.position.trim() === "") {
              errors.push("Position is required");
            }
          }

          if ( settings.email.required && settings.email.show ) {
            let emailIsValid = true;
            for (let emailObject of form.emails) {
              if (!emailObject.email.address || emailObject.email.address.trim() === "") {
                errors.push("Email address can't be empty");
                emailIsValid = false;
              } else {
                if (!emailObject.email.isValid) {
                  emailIsValid = false;
                  errors.push("Email address is not a valid email address");
                }
              }
            }
          }

          if (settings.language.required && settings.language.show) {
            if (!form.language || form.language.trim() === "") {
              errors.push("Language is required");
            }
          }
          if (settings.birthdate.required && settings.birthdate.show ) {
            if (!form.birthdate || form.birthdate.trim() === "") {
              errors.push("Date of Birth is required");
            }
          }
          
          for (let item of settings.customFields) {
            if (item.required && (!(form.customFields[item._id]) || form.customFields[item._id].trim() === "")) {
                errors.push(`${item.title} is required`);
            }
          }
    
          

          
         
          return errors;



        },
        customerFormValidation: (form, settings) => {
          function toId(str){
            return str.toLowerCase().replace(/[^a-z0-9]/g, '_');
          };
          const errors = [];
          
          if (form.type === "individual") {
            if (!form.fullname.firstName || form.fullname.firstName.trim() === "") {
              errors.push("First name is required");
            }
            if (!form.fullname.lastName || form.fullname.lastName.trim() === "") {
              errors.push("Last name is required");
            }
            
            if (settings.birthdate.required && settings.birthdate.show_individual ) {
              if (!form.birthdate || form.birthdate.trim() === "") {
                errors.push("Date of Birth is required");
              }
            }
    
            if (settings.gender.required && settings.gender.show_individual ) {
              if (!form.genderData || form.genderData.selectedGender.trim() === "") {
                errors.push("Gender is required");
              }
            }
    
            if (settings.language.required && settings.language.show_individual ) {
              if (!form.language || form.language.trim() === "") {
                errors.push("Language is required");
              }
            }
          }
          
          if (form.type === "corporate") {
            if (!form.name || form.name.trim() === "") {
              errors.push("Company name is required");
            }
            if ((settings.website.required_corporate && settings.website.show_corporate) && (!form.website || form.website.trim() === "")) {
              errors.push("Website is required");
            }
          }
    
          if ( (settings.email.required_corporate && settings.email.show_corporate && form.type === "corporate") ||  (settings.email.required_individual && settings.email.show_individual && form.type === "individual") ) {
            let emailIsValid = true;
            for (let emailObject of form.emails) {
              if (!emailObject.email.address || emailObject.email.address.trim() === "") {
                errors.push("Email address can't be empty");
                emailIsValid = false;
              } else {
                if (!emailObject.email.isValid) {
                  emailIsValid = false;
                  errors.push("Email address is not a valid email address");
                }
              }
            }
          }
          
    
          if (( (settings.phone.required_corporate && settings.phone.show_corporate && form.type === "corporate") || (settings.phone.required_individual && settings.phone.show_individual && form.type === "individual")) && ((settings.phone.show_corporate && form.type === "corporate")|| (settings.phone.show_individual && form.type === "individual"))) {
            let phoneIsValid = true;
            for (let phoneObject of form.phones) {
              if (!phoneObject.phone.number || phoneObject.phone.number.trim() === "") {
                errors.push("Phone number can't be empty");
                phoneIsValid = false;
              } else {
                if (!phoneObject.phone.isValid) {
                  phoneIsValid = false;
                  errors.push("Phone number is not a valid phone number");
                }
              }
            }
            //  errors.push(" is required");
          }
    
    
    
          if ( settings.address.required && ((settings.address.show_corporate && form.type === "corporate") || (settings.address.show_individual && form.type === "individual")) ){
            if ((form.address && !form.address.street)) {
              errors.push("Address Line (street) is required");
            }
            if ((form.address && !form.address.country)) {
              errors.push("Country is required");
            }
            if ((form.address && !form.address.state)) {
              errors.push("State is required");
            }
            if ((form.address && !form.address.city)) {
              errors.push("City is required");
            }
            if ((form.address && !form.address.zip)) {
              errors.push("ZIP code is required");
            }
          }
        
     
    
          for (let item of settings.customFields) {
            if (item.required && (!(form.customFields[item._id]) || form.customFields[item._id].trim() === "")) {
              
              if ((form.type === "corporate" && item.show_corporate)|| (form.type === "individual" && item.show_individual) ) {
                errors.push(`${item.title} is required`);
              }
              // errors.push(`${item.title} is required`);
            }
          }
    
          
          if ( (settings.info.required_corporate && settings.info.show_corporate && form.type === "corporate") ||  (settings.info.required_individual && settings.info.show_individual && form.type === "individual") ) {
            if (!form.info || form.info.trim() === "") {
              errors.push("Additional info should contain some information");
            }
          }
          return errors;
        }
          
      }
    };
  });