export const useCRMAccountSettings = () => useState("CRMAccountSettings", () => {
    return ref({});
});


// export const useCRMContactsSchema = () => useState("CRMContactsSchema", () => {
//     return ref([
//         {   
//             id: "greencard", 
//             kind: 'custom',
//             type: 'select', 
//             name: 'Provided Greencard?', 
//             show_in_form: true,
//             show_in_list: true,
//             show_as_badge: false,
//             show_filter: true,
//             options: [
//                 { value: 1, name: 'Yes', color: 'warning' }, 
//                 { value: 2, name: 'No', color: 'primary'},
//             ],
//             default: 2,

//         },
//         {   
//             id: "greencard_number", 
//             depends_on: "greencard",
//             depends_on_value: 1,
//             is_required: true, 
//             kind: 'custom',
//             type: 'text', 
//             name: 'Green Card Number',
//             show_in_form: true,
//             show_in_list: true,
//             show_filter: true,
//             default: " ",
//         },
//     ]);
// });