

<template>
    <keep-alive>
    <SubheaderCallcenterSettings></SubheaderCallcenterSettings>
    </keep-alive>
        <div class="row layout-top-spacing">
            <div class="col-12 layout-spacing">
                <div class="panel br-6">

                    <div class="row ms-2 mt-3 mb-3">
                        <div class="col-6 text-start">
                            <!-- {{callDirection}} -->
                            <selectBox
                                v-model="callDirection"
                                :options="callDirectionOptions"
                                title = "Call Direction"
                                :onChange ="getData"
                            />
                        </div>
                        <!-- <div class="col text-end me-2"> -->
                            <!-- <selectBox
                                v-model="per_page"
                                :options="[{name:'10', value:10},{name:'20', value:20}, {name:'50', value:50}, {name:'100', value:100}]"
                                title = "Rows on Page"
                                :onChange ="getData"
                            /> -->
                            <!-- <button class="btn btn-outline-primary btn-xs" title="Filters">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-filter" data-v-5522efca=""><polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon></svg>
                            </button> -->
                            
                        <!-- </div> -->
                        <!-- <div class="col text-start me-2">
                        </div> -->
                        
                        <div class="col flex-column align-items-end">
                            <selectBox
                                v-model="per_page"
                                :options="[{name:'10', value:10},{name:'20', value:20}, {name:'50', value:50}, {name:'100', value:100}]"
                                title = "Rows on Page"
                                :onChange ="getData"
                            />
                        </div>
                    </div>
                    <!-- {{tableData.data[9]}} -->
                    <!-- {{maxPages}} -->
                    <div class="custom-table panel-body p-0 text-start me-2 ms-2">
                        <table class="table table-hover table-striped ">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <!-- <th>User</th> -->
                                    <th>Caller ID</th>
                                    <th>Destination number</th>
                                    <th>Duration</th>
                                    <th>Charged</th>
                                    <th>Status</th>
                                    <th>Recording</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item of tableData.data">
                                    <td>{{$toHumanDate(item.start_epoch)}}</td>
                                    <!-- <td>{{item.from_user}}</td> -->
                                    <td v-if="callDirection == 'outgoing'">{{decodeURIComponent(item.caller_name)}}<br>{{urldecode(item.from_number)}}</td>
                                    <td v-if="callDirection == 'incoming'">{{urldecode(item.from_user)}}</td>
                                    <td>{{urldecode(item.to_number)}}<br><span v-if="callDirection == 'outgoing'">{{item.country}}</span></td>
                                    <td>{{$shortHumanFriendlyDuration(item.billsec)}}</td>
                                    <td>{{humanReadableAmount(item.charged)}}</td>
                                    <!-- {{item.hangup_cause}} -->
                                    <td>
                                        <span class="text-secondary"v-if="item.hangup_cause=='CALL_REJECTED'">Rejected</span>
                                        <span class="text-success" v-if="item.hangup_cause=='NORMAL_CLEARING'">Success</span>
                                        <span  class="text-secondary" v-if="item.hangup_cause=='ORIGINATOR_CANCEL'">Canceled</span>
                                        <span class="text-secondary" v-if="item.hangup_cause=='NORMAL_TEMPORARY_FAILURE'">Failed</span>
                                        
                                        
                                    </td>
                                    <td>
                                      
                                        <button v-if="item.has_recording" @click="playRecording(item)" title="Play recording" class="btn btn-outline-danger" style="max-height:25px;max-width:145px;padding: 0rem 0rem">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-play-circle" data-v-5522efca=""><circle cx="12" cy="12" r="10"></circle><polygon points="10 8 16 12 10 16 10 8"></polygon></svg>
                                        </button>
                                        <button v-if="item.has_recording" @click="downloadRecording(item);" title="Download recording" class="btn btn-outline-dark ms-2" style="max-height:25px;max-width:145px;padding: 0rem 0rem">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download" data-v-5522efca=""><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        
                        <div>
    
                            <audio ref="audioPlayer"preload="auto"></audio>
                    </div>
                        
                    </div>
                    <div class="paginating-container pagination-default flex-column align-items-end mb-4 me-3">
                    <ul role="menubar" aria-disabled="false" aria-label="Pagination" class="pagination b-pagination">
                        <li role="presentation" class="page-item prev" :disabled="current_page==1">
                            <button role="menuitem" type="button" tabindex="-1" aria-label="Go to previous page" class="page-link"  @click="goToPreviousPage()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path>
                                </svg>
                            </button>
                        </li>
                        <li v-for="item of pager" role="presentation" class="page-item" :class="item == current_page ? 'active' : ''">
                            <button @click="goToPage(item)" role="menuitemradio" type="button" aria-label="Go to page 1" aria-checked="false" aria-posinset="1" aria-setsize="3" tabindex="-1" class="page-link">
                                {{item}}
                            </button>
                        </li>
                        <li role="presentation" class="page-item next">
                            <a :href="signedUrl" download role="menuitem" type="button" tabindex="-1" aria-label="Go to next page" class="page-link" @click=goToNextPage()>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                                </svg>
                            </a>
                        </li>
                    </ul>
                    
</div>
                        <!-- <v-client-table 
                            :data="items" 
                            :columns="columns" 
                            :options="table_option" 
                        >
                        <template #from_number="props"> <strong>+{{ props.row.from_number }}</strong><span class="mobile"><br>{{props.row.created}}</span> </template>
                        <template #to_number="props"> +{{ props.row.to_number }}</template>
                        <template #charged="props"> {{ toCents(props.row.charged) }} </template>
                        <template #reason="props"> 
                            <p class="text-center">
                                <span v-if="props.row.reason == 'decline'" class="badge badge-danger me-1">{{$t("stat.inbound.reasons.declined")}}</span>
                                <span v-if="props.row.reason == 'ok'" class="badge badge-success me-1">{{$t("stat.inbound.reasons.success")}}</span>
                                <span v-if="props.row.reason == 'vm_redirected'" class="badge badge-primary me-1">{{$t("stat.inbound.reasons.voicemail")}}</span>
                                <span v-if="props.row.reason == 'request_terminated' || props.row.reason == 'not_acceptable_here'" class="badge badge-danger me-1">{{$t("stat.inbound.reasons.missed")}}</span>
                                <span v-if="props.row.reason == 'request_timeout'" class="badge badge-danger me-1">{{$t("stat.inbound.reasons.missed")}}</span>
                                <span v-if="props.row.reason == 'busy_here'" class="badge badge-warning me-1">{{$t("stat.inbound.reasons.busy")}}</span>
                                <span v-if="props.row.reason == 'not_here'" class="badge badge-warning me-1">{{$t("stat.inbound.reasons.offline")}}</span>
                                <div v-if="props.row.charged>0" class="mobile">
                                    <br>
                                    <!- - {{ toCents(props.row.charged) }} -- >
                                </div>
                            </p>
                            
                            <!- - {{props.row.reason}} -- >
                        </template>
                        </v-client-table>
                    </div> -->
                </div>
            </div>
        </div>
    
</template>

<script setup>
    
    import jsPDF from 'jspdf';
    import 'jspdf-autotable';
    
    // import http from '@/shared-lib/http';

    
    import {useI18n} from "vue-i18n";
    const i18n = useI18n();
    const audio = new Audio();

    const bc = useBreadcrumbs();
    const {$get, $toInternationalFormat, $toHumanDate} = useNuxtApp();
    const backButtonUrl = useBreadcrumbsMobileBackButton();
    const callcenter_id = sessionStorage.getItem("callcenter_id");
    const callcenter_name = sessionStorage.getItem("callcenter_name");
    const country = sessionStorage.getItem("country");

    const tableData = ref([]);
    const current_page = ref(1);
    const per_page = ref(20);
    
    const callDirection = ref("outgoing");
    const callDirectionOptions = ref(
        [
            {name:"Incoming calls", value:"incoming"},
            {name:"Outgoing Calls", value:"outgoing"}
        ]
    )

    const audioPlayer = ref(null);

    const playRecording = async (item) => {
        let data = await $get(`/api/protected/services/recording?uuid=${item.uuid}`)
        let s3SignedUrl = data.data;
        audio.src = s3SignedUrl;
        audio.play();
    }

    const downloadRecording = async (item) => {
        let data = await $get(`/api/protected/services/recording?uuid=${item.uuid}`)
        let s3SignedUrl = data.data;
        const link = document.createElement('a');
        link.href = s3SignedUrl;
        link.setAttribute('download', '');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const getData = async () => {
        tableData.value = await $get(`/api/protected/cdr/outbound?current_page=${current_page.value}&per_page=${per_page.value}&direction=${callDirection.value}`);
    }
    
    // const toHumanDate = (ts) => {
    //     const date = new Date(ts * 1000)
    //     const humanFriendlyDate = date.toLocaleString(undefined, {
    //         dateStyle: 'medium', // You can change this to 'full', 'long', 'short' as per your preference
    //         timeStyle: 'short', // Includes the time in the format
    //     });
    //     return humanFriendlyDate
    // } 

    // const shortHumanFriendlyDuration = (seconds) => {
    //      // Calculate hours, minutes, and seconds
    //     const hours = Math.floor(seconds / 3600);
    //     const minutes = Math.floor((seconds % 3600) / 60);
    //     const remainingSeconds = seconds % 60;
 
    //     // Build the short format (e.g., "1h 2m 5s")
    //     const hoursDisplay = hours > 0 ? `${hours}h` : '';
    //     const minutesDisplay = minutes > 0 ? `${minutes}m` : '';
    //     const secondsDisplay = remainingSeconds > 0 ? `${remainingSeconds}s` : '';
    //     console.log([hoursDisplay, minutesDisplay, secondsDisplay])
    //     // Combine into a string, filtering out empty parts
    //     if (!hoursDisplay && !minutesDisplay && !secondsDisplay){
    //         return "-"
    //     } else {
    //         return [hoursDisplay, minutesDisplay, secondsDisplay].filter(Boolean).join(' ');
    //     }
    // }

    const humanReadableAmount = (cents) => {
        const dollars = (cents / 10000).toFixed(2);
        // Return the formatted string with dollar sign
        return `$${dollars}`;
    }

    const urldecode = (str) => {
        if (str) {
            try {
               let n = $toInternationalFormat(decodeURIComponent(str),country);
               return n
            }  catch (error){
                console.log(error);
                return decodeURIComponent(str);
            }
            return 
        } else {
            return str
        }
    }


    // Paginagtion
    const goToPreviousPage = () => {
        if (current_page.value > 1) {
            current_page.value --;
            getData();

        }
    }

    const goToNextPage = () => {
        const totalCount = tableData.value.totalCount;
        const pageLimit = tableData.value.pageLimit
        const totalPages = Math.ceil(totalCount / pageLimit);
        if (current_page.value < totalPages) {
            current_page.value ++;
            getData();
        }
    }
    const goToPage = (page) => {
        current_page.value = page;
        getData();
    }


    const pager = computed(()=>{
        const pageLimit = tableData.value.pageLimit
        const page = current_page.value;
        const totalCount = tableData.value.totalCount;
         // Calculate total number of pages
        const totalPages = Math.ceil(totalCount / pageLimit);
  
        
  
        // Determine the range of pages to display (2 on the left and 2 on the right of the current page)
        const startPage = Math.max(1, page - 5);
        const endPage = Math.min(totalPages, page + 5);
        
        // Create an array of page numbers
        const pages = [];
        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }
        
        return pages;

        // return tableData.value.totalCount;
        // return Math.ceil(tableData.value.totalCount/ tableData.value.pageLimit);
    })


    bc.value = [
        { name: "Main", is_current: false , url: `/company/`},
        { name: "Call Centers", is_current: false , url: `/company/call-centers/`},
        { name: callcenter_name, is_current: true , url: `/company/call-centers/${callcenter_id}/calls`},
    ];
    backButtonUrl.value = "/company/call-centers/";
    
    definePageMeta({
        middleware: "auth",
        layout: 'protected'
    });

 
    
    

    const columns = ref(['from_number', 'to_number', 'created', 'charged', 'reason']);
    const items = ref([]);
    const table_option = ref({ resizableColumns: false, perPage: 20, perPageValues: [10, 20, 50],  texts: {count:  "{from}-{to} / {count}"}});
   
    onMounted(() => {
        current_page.value =1 ;
        getData();
        // useMeta({ title: i18n.t("stat.inbound.title.inbcalls") });
        // let object = {
        //     perPage: 20,
        //     perPageValues: [20, 50, 100, 500],
        //     skin: 'table table-hover table-striped',
        //     columnsClasses: { from_number: 'actions', to_number:'desktop', created:'desktop', charged:'desktop' },
        //     pagination: { nav: 'scroll', chunk: 2 },
        //     headings: {
        //         from_number: i18n.t("stat.inbound.from_number"),
        //         to_number: i18n.t("stat.inbound.to_number"),
        //         created: i18n.t("stat.inbound.created"),
        //         charged: i18n.t("stat.inbound.charged"),
        //         reason: i18n.t("stat.inbound.reason"),
        //     },
        //     texts: {
        //         count: "{from}-{to} / {count}", // i18n.t("stat.inbound.label.showing"),
        //         filter: '',
        //         filterPlaceholder:  i18n.t("stat.inbound.label.search"),
        //         limit: i18n.t("stat.inbound.label.records"),
        //         noResults: i18n.t("stat.message.noresults.records"),
        //     },
        //     //sortable: ['from_number', 'to_number', 'office', 'created', 'charged', 'reason'],
        //     sortable: [],
        //     sortIcon: {
        //         base: 'sort-icon-none',
        //         up: 'sort-icon-asc',
        //         down: 'sort-icon-desc',
        //     },
        //     resizableColumns: false,
        // };
        // table_option.value = object;
        // table_option.value.texts.count = i18n.t("stat.inbound.label.showing");
        
        let res = [];
        // $get(`/api/protected/cdr/incoming`)
        // .then(response => {
        //     for (let item of response.data){
        //         let itemObject = {};
        //         for (let key in item){
        //             switch (key){
        //                 case "created":
        //                     itemObject[key] = toHumanDate(item[key]); 
        //                 break;

        //                 default:
        //                     itemObject[key] = item[key];
        //                 break;
        //             }
        //         }
        //         res.push(itemObject);
        //     }
        //     items.value = res;
        // });
        // bind_data();
    });



    const export_table = (type) => {
        let cols = columns.value.filter((d) => d != 'profile' && d != 'action');
        let records = items.value;
        let filename = 'table';

        if (type == 'csv') {
            let coldelimiter = ',';
            let linedelimiter = '\n';
            let result = cols
                .map((d) => {
                    return capitalize(d);
                })
                .join(coldelimiter);
            result += linedelimiter;
            records.map((item) => {
                cols.map((d, index) => {
                    if (index > 0) {
                        result += coldelimiter;
                    }
                    let val = item[d] ? item[d] : '';
                    result += val;
                });
                result += linedelimiter;
            });

            if (result == null) return;
            if (!result.match(/^data:text\/csv/i) && !window.navigator.msSaveOrOpenBlob) {
                var data = 'data:application/csv;charset=utf-8,' + encodeURIComponent(result);
                var link = document.createElement('a');
                link.setAttribute('href', data);
                link.setAttribute('download', filename + '.csv');
                link.click();
            } else {
                var blob = new Blob([result]);
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveBlob(blob, filename + '.csv');
                }
            }
        } else if (type == 'print') {
            var rowhtml = '<p>' + filename + '</p>';
            rowhtml +=
                '<table style="width: 100%; " cellpadding="0" cellcpacing="0"><thead><tr style="color: #515365; background: #eff5ff; -webkit-print-color-adjust: exact; print-color-adjust: exact; "> ';
            cols.map((d) => {
                rowhtml += '<th>' + capitalize(d) + '</th>';
            });
            rowhtml += '</tr></thead>';
            rowhtml += '<tbody>';

            records.map((item) => {
                rowhtml += '<tr>';
                cols.map((d) => {
                    let val = item[d] ? item[d] : '';
                    rowhtml += '<td>' + val + '</td>';
                });
                rowhtml += '</tr>';
            });
            rowhtml +=
                '<style>body {font-family:Arial; color:#495057;}p{text-align:center;font-size:18px;font-weight:bold;margin:15px;}table{ border-collapse: collapse; border-spacing: 0; }th,td{font-size:12px;text-align:left;padding: 4px;}th{padding:8px 4px;}tr:nth-child(2n-1){background:#f7f7f7; }</style>';
            rowhtml += '</tbody></table>';
            var winPrint = window.open('', '', 'left=0,top=0,width=1000,height=600,toolbar=0,scrollbars=0,status=0');
            winPrint.document.write('<title>Print</title>' + rowhtml);
            winPrint.document.close();
            winPrint.focus();
            winPrint.print();
            // winPrint.close();
        } else if (type == 'pdf') {
            cols = cols.map((d) => {
                return { header: capitalize(d), dataKey: d };
            });
            const doc = new jsPDF('l', 'pt', cols.length > 10 ? 'a3' : 'a4');
            doc.autoTable({
                headStyles: { fillColor: '#eff5ff', textColor: '#515365' },
                columns: cols,
                body: records,
                styles: { overflow: 'linebreak' },
                pageBreak: 'auto',
                margin: { top: 45 },
                didDrawPage: () => {
                    doc.text('Export Table', cols.length > 10 ? 535 : 365, 30);
                },
            });
            doc.save(filename + '.pdf');
        }
    };

    const capitalize = (text) => {
        return text
            .replace('_', ' ')
            .replace('-', ' ')
            .toLowerCase()
            .split(' ')
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    };


    // function toHumanDate(d){
    //     const milliseconds = d * 1000
    //     const dateObject = new Date(milliseconds)
    //     const humanDateFormat = dateObject.toLocaleString()
    //     return humanDateFormat;
    // }

    function getCustomHeader(field) {
        const column = columns.value.find((col) => col.field === field);
        return column ? column.label : '';
    }

    // function toCents(amount) {
    //     // if (amount<0.01) {
    //     //     return `$${(amount*100).toFixed(2)}`;
    //     // } else
    //     return `$${(amount/10000).toFixed(2)}`;
    // }
</script>

