<template>
  <div class="input-group">
    <!-- <input type="text" class="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2" /> -->
      <vue-tel-input
        :all-countries="customCountryList"
        v-model="displayedPhone"
        mode="national"
        :disabled="disabled"
        
        :class="props.mini ? 'form-control-address form-control' : 'form-control'"
        @on-input="phoneEntered"
      ></vue-tel-input>
      <span v-if="isValid" class="input-group-text">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check green-stroke" >
              <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
      </span>
      <span v-if="!isValid" class="input-group-text">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x red-stroke" ><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
      </span>
  </div>
    
    <!-- <div class="valid-feedback">Looks good!</div> -->
            <!-- <div class="invalid-feedback">Please fill the name</div> -->
  </template>

  
  <script setup>
  import { ref, getCurrentInstance, watch } from 'vue';
  import { VueTelInput } from 'vue-tel-input';
  import 'vue-tel-input/vue-tel-input.css';
  import { parsePhoneNumberFromString } from 'libphonenumber-js';
  
  const formatPhoneNumber = (rawNumber) => {
    if (!rawNumber) return '';
  
    const phoneNumber = parsePhoneNumberFromString(rawNumber);
    if (phoneNumber) {
      return phoneNumber.formatNational(); // Display in national format
    }
    return rawNumber;
  };
  
  const instance = getCurrentInstance();
  
  const customCountryList = usePhoneCountryList();
  const isValid = ref(false);
  
  const props = defineProps({
    modelValue: { type: Object, default: () => ({ number: '', isValid: false }) },
    disabled: { type: Boolean, default: false },
    mini: { type: Boolean, default: false },
  });
  
  const emit = instance.emit;
  
  const phone = ref({ ...props.modelValue });
  const displayedPhone = ref(formatPhoneNumber(props.modelValue.number));
  
  watch(
    () => props.modelValue,
    (newValue) => {
      if (newValue.number !== phone.value.number || newValue.isValid !== phone.value.isValid) {
        phone.value = { ...newValue };
        displayedPhone.value = formatPhoneNumber(newValue.number);
      }
    },
    { deep: true, immediate: true }
  );
  
  watch(
    phone,
    (newValue) => {
      emit('update:modelValue', { ...newValue });
    },
    { deep: true }
  );
  
  const phoneEntered = (number, object) => {
    isValid.value = object.valid;
    if (phone.value.number !== object.number || phone.value.isValid !== object.valid) {
      phone.value = { number: object.number, isValid: object.valid };
      displayedPhone.value = formatPhoneNumber(object.number);
      emit('update:modelValue', { number: object.number, isValid: object.valid });
    }
  };
  
  </script>
  
  <style>
  .green-stroke {
    stroke: green;
  }
  .form-control-address {
    height: 40px;
  }

  .red-stroke{
    stroke: red;
  }
  </style>