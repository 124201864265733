<template>
    <div class="input-group mb-4">
      <input
        type="email"
        v-model="emailObject.address"
        :disabled="disabled"
        class="form-control"
      />
      <span v-if="isValid" class="input-group-text">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="valid-icon"
        >
          <polyline points="20 6 9 17 4 12"></polyline>
        </svg>
      </span>
      <span v-if="!isValid" class="input-group-text">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="invalid-icon"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </span>
    </div>
  </template>
  
  <script setup>
  import { ref, watch } from 'vue';
  
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };
  
  const props = defineProps({
    modelValue: {
      type: Object,
      default: () => ({ address: '', isValid: false }),
    },
    disabled: { type: Boolean, default: false },
  });
  
  const emit = defineEmits(['update:modelValue']);
  
  const emailObject = ref({ ...props.modelValue });
  const isValid = ref(validateEmail(emailObject.value.address));
  
  // Watch for changes in modelValue and update local state
  watch(
    () => props.modelValue,
    (newValue) => {
      emailObject.value = { ...newValue };
      isValid.value = validateEmail(emailObject.value.address);
    },
    { immediate: true, deep: true }
  );
  
  // Watch for local input changes and emit updates
  watch(
    () => emailObject.value.address,
    (newValue) => {
      isValid.value = validateEmail(newValue);
      emit('update:modelValue', { ...emailObject.value, isValid: isValid.value });
    }
  );
  </script>
  
  <style>
  .valid-icon {
    stroke: green;
  }
  .invalid-icon {
    stroke: red;
  }
  </style>
  