<template>

  
  <div class="row" v-if="props && props.isNew && account && accountSettings && accountSettings.servedTypes=='all'">
    <div class="col-12">
      <label class="form-label">Type of the Customer <span class="text-danger">*</span></label>
      <selectBox
        v-model="account.type"
        :options="[{name:'Company', value:'corporate'}, {name:'Individual', value:'individual'}]"
        :clearable="false"
        :preselectFirst=true
        required
      />
    </div>
  </div>
  

  <div class="row mt-4" v-if="account.type == 'corporate'">
    <div class="col-12">
      <label class="form-label">Company Name <span class="text-danger">*</span></label>
      <input
        type="text"
        class="form-control"
        v-model="account.name"
        required
      />
    </div>
  </div>
  <div class="row mt-4" v-if="account.type == 'individual'">
    <div class="col-12">
      <!-- <label class="form-label">Customer Name <span class="text-danger">*</span></label> -->
      <fullname v-model="account.fullname"/>
    </div>
  </div>

  <div class="row mt-2" v-if="account.type == 'individual'">
    <div class="col-12">
      <div class="mt-3 mb-4" v-if="accountSettings.birthdate && accountSettings.birthdate.show_individual">
        <label class="form-label">Birthday<span v-if="accountSettings.birthdate.required" class="text-danger">*</span></label>
        <BirthDate v-model="account.birthdate"/>
    </div>
    </div>
  </div>

  <div class="row mt-2" v-if="account.type == 'individual'">
    <div class="col-12">

      <div  v-if="accountSettings.gender && accountSettings.gender.show_individual">
        <label class="form-label">Gender<span v-if="accountSettings.gender.required" class="text-danger">*</span></label>
        
        <gender v-model="account.genderData" />
    </div>
    </div>
  </div>
  

  
  <div class="row mt-4" v-if="account.type == 'corporate' && accountSettings && accountSettings.website && accountSettings.website.show_corporate">
  <!-- {{accountSettings.website}} -->
      <div class="col-12">
        <label for="website" class="form-label">Company Website <span v-if="accountSettings.website.required_corporate" class="text-danger">*</span></label>
        <input
          type="text"
          class="form-control"
          id="website"
          v-model="account.website"
        />
      </div>
  
  </div>

  <div class="mt-4" v-if="accountSettings && accountSettings.email && ((account.type == 'corporate' && accountSettings.email.show_corporate) || (account.type == 'individual' && accountSettings.email.show_individual))">
    <div class="row" v-for="(item, index) in account.emails" :key="index" >
      <div :class="account.emails.length>1 ? 'col-5' : 'col-5'"  >
        <label for="email" class="form-label">Email Address <span v-if="(accountSettings.email.required_corporate && account.type == 'corporate') || (account.type == 'individual' && accountSettings.email.required_individual)" class="text-danger">*</span></label>
    
        <emailInput v-model="account.emails[index].email"  style="height:40px;width:100%;z-index:0" />
        <!-- <input class="form-control" type="text" v-model="item.email.address" /> -->

      </div>
      <div class="col-4">
        <label for="email" class="form-label">&nbsp</label>
        <input
          type="text"
          class="form-control"
          id="email"
          v-model="item.hint"
        />
        <small class="form-text text-muted">e.g: Sales, Customer Service </small>
      </div>
      <div class="text-end" :class="account.emails.length>1 ? 'col-3' : 'col-3'">
        <label for="phone" class="form-label">&nbsp<br> </label>
        <div>
          <button  :disabled="account.emails.length==1" class="btn btn-lg btn-outline-dark" style="min-height:54px" @click="account.emails.splice(index, 1);">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2">
              <polyline points="3 6 5 6 21 6"></polyline>
              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
              <line x1="10" y1="11" x2="10" y2="17"></line>
              <line x1="14" y1="11" x2="14" y2="17"></line>
            </svg>
          </button>
          <button 
            :disabled="!item.email.isValid || index!=account.emails.length-1" 
            class="btn btn-lg btn-outline-primary ms-2" 
            style="min-height:54px" 
            @click="account.emails.push({email:{address:'', isValid:false} })"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus" data-v-5522efca=""><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
          </button>
        </div>
      </div>
    </div>
  </div>


  <div class="mt-4" v-if="accountSettings && accountSettings.phone && ((accountSettings.phone.show_corporate &&account.type == 'corporate')|| (accountSettings.phone.show_individual &&account.type == 'individual'))">
    <div class="row" v-for="(item, index) in account.phones" :key="index" >
      <div :class="account.phones.length>1 ? 'col-5' : 'col-5'"  >
        <label for="phone" class="form-label">Phone Number <span v-if="(accountSettings.phone.required_corporate  && account.type == 'corporate') || (accountSettings.phone.required_individual  && account.type == 'individual') " class="text-danger">*</span></label>
        
        <phoneInput v-model="item.phone"  style="height:40px;width:100%;z-index:0" />
      </div>
      <div class="col-4">
        <label for="phone" class="form-label">&nbsp</label>
        <input
          type="text"
          class="form-control"
          id="phone"
          v-model="item.hint"
        />
        <small class="form-text text-muted">e.g: Sales, Customer Service </small>
      </div>
      <div class="text-end" :class="account.phones.length>1 ? 'col-3' : 'col-3'">
        <label for="phone" class="form-label">&nbsp<br> </label>
        <div>
          <button  :disabled="account.phones.length==1" class="btn btn-lg btn-outline-dark" style="min-height:54px" @click="account.phones.splice(index, 1);">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2">
              <polyline points="3 6 5 6 21 6"></polyline>
              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
              <line x1="10" y1="11" x2="10" y2="17"></line>
              <line x1="14" y1="11" x2="14" y2="17"></line>
            </svg>
          </button>
          <button :disabled="!item.phone.isValid || index!=account.phones.length-1" class="btn btn-lg btn-outline-primary ms-2" style="min-height:54px" @click="account.phones.push({phone:{} })">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus" data-v-5522efca=""><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-4 " v-if="accountSettings && accountSettings.address && ((accountSettings.address.show_corporate == true && account.type=='corporate') || (accountSettings.address.show_individual == true && account.type=='individual'))">
    <div class="col-12" >
        <Address  v-model="account.address"/>
    </div>
  </div>
  <div class="row mt-4 " v-if="accountSettings && accountSettings.language && ((accountSettings.language.show_corporate == true && account.type=='corporate') || (accountSettings.language.show_individual == true && account.type=='individual'))">
    <label for="phone" class="form-label">Preffered Language <span v-if="accountSettings.language.required" class="text-danger">*</span></label>
    <div class="col-12" >
        <LanguagesLanguageSelector  v-model="account.language"/>
    </div>
  </div>

  
  

  <div class="mb-3 row" v-if="accountSettings && accountSettings.customFields" v-for="field of accountSettings.customFields">
    <span v-if="field.show_corporate && account.type == 'corporate' || field.show_individual && account.type == 'individual'">

      <span v-if="(field.admin_only == true && userRole == 'admin') || (!field.admin_only)">
        
        <div class="pt-3 col" v-if="field.type === 'select'" >
            <label :for="field.title" class="form-label">{{ field.title }}<span v-if="field.required" class="text-danger">*</span></label>
            <selectBox v-if="account.customFields" v-model="account.customFields[field._id]" :options = "field.options"/>
              
              <!-- <small class="form-text text-muted">{{ field.hint }}</small> -->
        </div>
        <div class="pt-3 col" v-if="field.type === 'text'" >
            <div>
                <label :for="field._id" class="form-label">{{ field.title }}<span v-if="field.required" class="text-danger">*</span></label>
                <input v-if="account.customFields" type="text" v-model="account.customFields[field._id]" class="form-control" :name="field.title" required>
                <small class="form-text text-muted">{{ field.hint }}</small>
            </div>
        </div>
      </span>
    </span>
  </div>
  <!-- {{accountSettings.info}} -->
  <div class="row" v-if="accountSettings && accountSettings.info && (( accountSettings.info.show_corporate && account.type == 'corporate')|| ( accountSettings.info.show_individual && account.type == 'individual'))">
    <!-- [[[[]]]] -->
    <div class="mb-3 mt-4 col-12">
      <label for="info" class="form-label">Additional Info<span v-if="accountSettings && accountSettings.info && ((accountSettings.info.required_corporate && account.type == 'corporate') || (accountSettings.info.required_individual && account.type == 'individual')  )" class="text-danger">*</span></label>
      <textarea
        ref="myTextarea"
        class="form-control"
        v-model="account.info"
        style="padding: 15px; min-height: 120px;"
      />
    </div>
  </div>
  
  
</template>

<script setup>
import { ref, watch } from 'vue';
import _ from 'lodash';
const accountSettings = useCRMAccountSettings()
// Props and Emits
const props = defineProps({
  modelValue: { type: Object, default: () => ({}) },
  isNew: { type: Boolean, default: false },
});
const emit = defineEmits(['update:modelValue']);

const userRole = ref("");
userRole.value = sessionStorage.getItem('role');
    

// Default account structure
const defaultAccount = {phones: [{phone: {}, hint: ''}], emails:[{email:{address:"", isValid:false}, hint:''}], address: {}};

// Reactive account object with default initialization
const account = ref({ ...defaultAccount, ...props.modelValue });
account.value = _.cloneDeep(account.value);

const toId = (str) => str.toLowerCase().replace(/[^a-z0-9]/g, '_');

// Watch for updates to modelValue and update account
watch(
  () => props.modelValue,
  (newValue) => {
    // alert(JSON.stringify(newValue))
    Object.assign(account.value,  newValue); // Ensure all fields are initialized
  
    if (account.value.phones.length == 0){
      account.value.phones.push({phone: {}, hint: ''});
    }
    if (account.value.emails.length == 0){
      account.value.emails.push({email:{address:"", isValid:false}, hint:''});
    }
    if (props.isNew == true && accountSettings.value.servedTypes!='all'){
      // alert(accountSettings.value.servedTypes)
      account.value.type = accountSettings.value.servedTypes;
    }
  },
  { deep: true, immediate: true }
);

// Emit updates to parent when account changes
watch(
  account,
  (newValue) => {
    emit('update:modelValue', newValue);
  },
  { deep: true }
);


  

</script>
