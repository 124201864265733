import { default as agentslNzzXOS587Meta } from "/app/pages/company/call-centers/[callcenter_id]/agents.vue?macro=true";
import { default as callsWxh51G7jxSMeta } from "/app/pages/company/call-centers/[callcenter_id]/calls.vue?macro=true";
import { default as hotlinesGBYwVXtSQXMeta } from "/app/pages/company/call-centers/[callcenter_id]/hotlines.vue?macro=true";
import { default as monitoringpNlA9NzaVeMeta } from "/app/pages/company/call-centers/[callcenter_id]/monitoring.vue?macro=true";
import { default as settings56HYK24BLxMeta } from "/app/pages/company/call-centers/[callcenter_id]/settings.vue?macro=true";
import { default as createIf1Cmpx8EqMeta } from "/app/pages/company/call-centers/create.vue?macro=true";
import { default as indexI8nG5PThYfMeta } from "/app/pages/company/call-centers/index.vue?macro=true";
import { default as create49c2VEwNwnMeta } from "/app/pages/company/create.vue?macro=true";
import { default as _91id_93amBauSsRVpMeta } from "/app/pages/company/customers/[id].vue?macro=true";
import { default as indexKVczSNmZ6OMeta } from "/app/pages/company/customers/index.vue?macro=true";
import { default as indexBlP2QBD3MrMeta } from "/app/pages/company/customers/settings/index.vue?macro=true";
import { default as indexadJieniFk0Meta } from "/app/pages/company/index.vue?macro=true";
import { default as createyuywxUUkQlMeta } from "/app/pages/create.vue?macro=true";
import { default as formBvm02kOGeVMeta } from "/app/pages/crm/company/form.vue?macro=true";
import { default as indexD8NrVWxb0dMeta } from "/app/pages/crm/company/index.vue?macro=true";
import { default as indexBtFO0EEcFuMeta } from "/app/pages/crm/contacts/index.vue?macro=true";
import { default as indexfP1ulDpUpnMeta } from "/app/pages/index_old/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as index7ESp5cHmPlMeta } from "/app/pages/invitations/index.vue?macro=true";
import { default as autoacceptsCYflXRBheMeta } from "/app/pages/login/autoaccept.vue?macro=true";
import { default as indexgK5VicK7y2Meta } from "/app/pages/login/index.vue?macro=true";
import { default as googlefwpS5czamDMeta } from "/app/pages/login/validate/google.vue?macro=true";
import { default as regularh82EC7cPoIMeta } from "/app/pages/login/validate/regular.vue?macro=true";
import { default as purchase_45did_45successV52RRkG63XMeta } from "/app/pages/payments/purchase-did-success.vue?macro=true";
import { default as upgrade_45successME0tNRgwz0Meta } from "/app/pages/payments/upgrade-success.vue?macro=true";
import { default as indexaeouN7Kzh7Meta } from "/app/pages/profile/index.vue?macro=true";
import { default as profileNU4FgOgffEMeta } from "/app/pages/profile/profile.vue?macro=true";
import { default as testL3AVDvhyXeMeta } from "/app/pages/test.vue?macro=true";
import { default as _1exY307y3TSMeta } from "/app/pages/tests/1.vue?macro=true";
import { default as _2COMUC9wIAMMeta } from "/app/pages/tests/2.vue?macro=true";
import { default as _3GNGTGOzkwOMeta } from "/app/pages/tests/3.vue?macro=true";
import { default as _4C3ulGqw9DlMeta } from "/app/pages/tests/4.vue?macro=true";
import { default as addrxKRvYIbvIiMeta } from "/app/pages/tests/addr.vue?macro=true";
import { default as agent_45hotlinesXhUbb2CeNGMeta } from "/app/pages/tests/agent-hotlines.vue?macro=true";
import { default as approvalsf7y0ICo6hNMeta } from "/app/pages/tests/approvals.vue?macro=true";
import { default as contactO3o5qXzo6sMeta } from "/app/pages/tests/crm/contact.vue?macro=true";
import { default as phone_45inputs45ZHJR049Meta } from "/app/pages/tests/crm/phone-input.vue?macro=true";
import { default as custom_45field3g0bPUbHGvMeta } from "/app/pages/tests/custom-field.vue?macro=true";
import { default as date2QptfIvg1YMeta } from "/app/pages/tests/date.vue?macro=true";
import { default as ddWD2QdFXylhMeta } from "/app/pages/tests/dd.vue?macro=true";
import { default as dtkId3W0LPMRMeta } from "/app/pages/tests/dt.vue?macro=true";
import { default as email_45inputVq3mgI8HEQMeta } from "/app/pages/tests/email-input.vue?macro=true";
import { default as fullnameWDysZjD5oQMeta } from "/app/pages/tests/fullname.vue?macro=true";
import { default as gender24TtkWqzquMeta } from "/app/pages/tests/gender.vue?macro=true";
import { default as hotlinesRcx83kM9pqMeta } from "/app/pages/tests/hotlines.vue?macro=true";
import { default as ivrsCoJpcKfjiMeta } from "/app/pages/tests/ivr.vue?macro=true";
import { default as language_45selection_45ivrgvftZKOnEOMeta } from "/app/pages/tests/language-selection-ivr.vue?macro=true";
import { default as popupAmvs9c3hNXMeta } from "/app/pages/tests/popup.vue?macro=true";
import { default as quill_45renderU7D6W21wnfMeta } from "/app/pages/tests/quill-render.vue?macro=true";
import { default as reactivegWQj2acnIfMeta } from "/app/pages/tests/reactive.vue?macro=true";
import { default as select_45tagmxhNwF686vMeta } from "/app/pages/tests/select-tag.vue?macro=true";
import { default as select1LTCVVI1zvMeta } from "/app/pages/tests/select.vue?macro=true";
import { default as sip_45test0AzFUsKUsQMeta } from "/app/pages/tests/sip-test.vue?macro=true";
import { default as sipXn9HzIop4bMeta } from "/app/pages/tests/sip.vue?macro=true";
import { default as stripe_45paymentGwFG90ZtI5Meta } from "/app/pages/tests/stripe-payment.vue?macro=true";
import { default as tel_45inputo5vJpP7Nd6Meta } from "/app/pages/tests/tel-input.vue?macro=true";
import { default as toastE9RyNpaOp9Meta } from "/app/pages/tests/toast.vue?macro=true";
import { default as useCompanyO41JOo7TMcMeta } from "/app/pages/tests/useCompany.vue?macro=true";
import { default as wskUGm4LV8aIMeta } from "/app/pages/tests/ws.vue?macro=true";
import { default as ws1pKmh4DAZvQMeta } from "/app/pages/tests/ws1.vue?macro=true";
import { default as wysiwgLoXOKcBh4XMeta } from "/app/pages/tests/wysiwg.vue?macro=true";
import { default as index2HgavUPmh3Meta } from "/app/pages/welcome/index.vue?macro=true";
export default [
  {
    name: "company-call-centers-callcenter_id-agents",
    path: "/company/call-centers/:callcenter_id()/agents",
    meta: agentslNzzXOS587Meta || {},
    component: () => import("/app/pages/company/call-centers/[callcenter_id]/agents.vue")
  },
  {
    name: "company-call-centers-callcenter_id-calls",
    path: "/company/call-centers/:callcenter_id()/calls",
    meta: callsWxh51G7jxSMeta || {},
    component: () => import("/app/pages/company/call-centers/[callcenter_id]/calls.vue")
  },
  {
    name: "company-call-centers-callcenter_id-hotlines",
    path: "/company/call-centers/:callcenter_id()/hotlines",
    meta: hotlinesGBYwVXtSQXMeta || {},
    component: () => import("/app/pages/company/call-centers/[callcenter_id]/hotlines.vue")
  },
  {
    name: "company-call-centers-callcenter_id-monitoring",
    path: "/company/call-centers/:callcenter_id()/monitoring",
    meta: monitoringpNlA9NzaVeMeta || {},
    component: () => import("/app/pages/company/call-centers/[callcenter_id]/monitoring.vue")
  },
  {
    name: "company-call-centers-callcenter_id-settings",
    path: "/company/call-centers/:callcenter_id()/settings",
    meta: settings56HYK24BLxMeta || {},
    component: () => import("/app/pages/company/call-centers/[callcenter_id]/settings.vue")
  },
  {
    name: "company-call-centers-create",
    path: "/company/call-centers/create",
    meta: createIf1Cmpx8EqMeta || {},
    component: () => import("/app/pages/company/call-centers/create.vue")
  },
  {
    name: "company-call-centers",
    path: "/company/call-centers",
    meta: indexI8nG5PThYfMeta || {},
    component: () => import("/app/pages/company/call-centers/index.vue")
  },
  {
    name: "company-create",
    path: "/company/create",
    meta: create49c2VEwNwnMeta || {},
    component: () => import("/app/pages/company/create.vue")
  },
  {
    name: "company-customers-id",
    path: "/company/customers/:id()",
    meta: _91id_93amBauSsRVpMeta || {},
    component: () => import("/app/pages/company/customers/[id].vue")
  },
  {
    name: "company-customers",
    path: "/company/customers",
    meta: indexKVczSNmZ6OMeta || {},
    component: () => import("/app/pages/company/customers/index.vue")
  },
  {
    name: "company-customers-settings",
    path: "/company/customers/settings",
    meta: indexBlP2QBD3MrMeta || {},
    component: () => import("/app/pages/company/customers/settings/index.vue")
  },
  {
    name: "company",
    path: "/company",
    meta: indexadJieniFk0Meta || {},
    component: () => import("/app/pages/company/index.vue")
  },
  {
    name: "create",
    path: "/create",
    meta: createyuywxUUkQlMeta || {},
    component: () => import("/app/pages/create.vue")
  },
  {
    name: "crm-company-form",
    path: "/crm/company/form",
    component: () => import("/app/pages/crm/company/form.vue")
  },
  {
    name: "crm-company",
    path: "/crm/company",
    meta: indexD8NrVWxb0dMeta || {},
    component: () => import("/app/pages/crm/company/index.vue")
  },
  {
    name: "crm-contacts",
    path: "/crm/contacts",
    meta: indexBtFO0EEcFuMeta || {},
    component: () => import("/app/pages/crm/contacts/index.vue")
  },
  {
    name: "index_old",
    path: "/index_old",
    meta: indexfP1ulDpUpnMeta || {},
    component: () => import("/app/pages/index_old/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "invitations",
    path: "/invitations",
    meta: index7ESp5cHmPlMeta || {},
    component: () => import("/app/pages/invitations/index.vue")
  },
  {
    name: "login-autoaccept",
    path: "/login/autoaccept",
    component: () => import("/app/pages/login/autoaccept.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexgK5VicK7y2Meta || {},
    component: () => import("/app/pages/login/index.vue")
  },
  {
    name: "login-validate-google",
    path: "/login/validate/google",
    component: () => import("/app/pages/login/validate/google.vue")
  },
  {
    name: "login-validate-regular",
    path: "/login/validate/regular",
    component: () => import("/app/pages/login/validate/regular.vue")
  },
  {
    name: "payments-purchase-did-success",
    path: "/payments/purchase-did-success",
    component: () => import("/app/pages/payments/purchase-did-success.vue")
  },
  {
    name: "payments-upgrade-success",
    path: "/payments/upgrade-success",
    component: () => import("/app/pages/payments/upgrade-success.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexaeouN7Kzh7Meta || {},
    component: () => import("/app/pages/profile/index.vue")
  },
  {
    name: "profile-profile",
    path: "/profile/profile",
    meta: profileNU4FgOgffEMeta || {},
    component: () => import("/app/pages/profile/profile.vue")
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/app/pages/test.vue")
  },
  {
    name: "tests-1",
    path: "/tests/1",
    component: () => import("/app/pages/tests/1.vue")
  },
  {
    name: "tests-2",
    path: "/tests/2",
    component: () => import("/app/pages/tests/2.vue")
  },
  {
    name: "tests-3",
    path: "/tests/3",
    component: () => import("/app/pages/tests/3.vue")
  },
  {
    name: "tests-4",
    path: "/tests/4",
    component: () => import("/app/pages/tests/4.vue")
  },
  {
    name: "tests-addr",
    path: "/tests/addr",
    component: () => import("/app/pages/tests/addr.vue")
  },
  {
    name: "tests-agent-hotlines",
    path: "/tests/agent-hotlines",
    component: () => import("/app/pages/tests/agent-hotlines.vue")
  },
  {
    name: "tests-approvals",
    path: "/tests/approvals",
    component: () => import("/app/pages/tests/approvals.vue")
  },
  {
    name: "tests-crm-contact",
    path: "/tests/crm/contact",
    component: () => import("/app/pages/tests/crm/contact.vue")
  },
  {
    name: "tests-crm-phone-input",
    path: "/tests/crm/phone-input",
    component: () => import("/app/pages/tests/crm/phone-input.vue")
  },
  {
    name: "tests-custom-field",
    path: "/tests/custom-field",
    component: () => import("/app/pages/tests/custom-field.vue")
  },
  {
    name: "tests-date",
    path: "/tests/date",
    component: () => import("/app/pages/tests/date.vue")
  },
  {
    name: "tests-dd",
    path: "/tests/dd",
    component: () => import("/app/pages/tests/dd.vue")
  },
  {
    name: "tests-dt",
    path: "/tests/dt",
    component: () => import("/app/pages/tests/dt.vue")
  },
  {
    name: "tests-email-input",
    path: "/tests/email-input",
    component: () => import("/app/pages/tests/email-input.vue")
  },
  {
    name: "tests-fullname",
    path: "/tests/fullname",
    component: () => import("/app/pages/tests/fullname.vue")
  },
  {
    name: "tests-gender",
    path: "/tests/gender",
    component: () => import("/app/pages/tests/gender.vue")
  },
  {
    name: "tests-hotlines",
    path: "/tests/hotlines",
    component: () => import("/app/pages/tests/hotlines.vue")
  },
  {
    name: "tests-ivr",
    path: "/tests/ivr",
    component: () => import("/app/pages/tests/ivr.vue")
  },
  {
    name: "tests-language-selection-ivr",
    path: "/tests/language-selection-ivr",
    component: () => import("/app/pages/tests/language-selection-ivr.vue")
  },
  {
    name: "tests-popup",
    path: "/tests/popup",
    component: () => import("/app/pages/tests/popup.vue")
  },
  {
    name: "tests-quill-render",
    path: "/tests/quill-render",
    component: () => import("/app/pages/tests/quill-render.vue")
  },
  {
    name: "tests-reactive",
    path: "/tests/reactive",
    component: () => import("/app/pages/tests/reactive.vue")
  },
  {
    name: "tests-select-tag",
    path: "/tests/select-tag",
    component: () => import("/app/pages/tests/select-tag.vue")
  },
  {
    name: "tests-select",
    path: "/tests/select",
    component: () => import("/app/pages/tests/select.vue")
  },
  {
    name: "tests-sip-test",
    path: "/tests/sip-test",
    component: () => import("/app/pages/tests/sip-test.vue")
  },
  {
    name: "tests-sip",
    path: "/tests/sip",
    component: () => import("/app/pages/tests/sip.vue")
  },
  {
    name: "tests-stripe-payment",
    path: "/tests/stripe-payment",
    component: () => import("/app/pages/tests/stripe-payment.vue")
  },
  {
    name: "tests-tel-input",
    path: "/tests/tel-input",
    component: () => import("/app/pages/tests/tel-input.vue")
  },
  {
    name: "tests-toast",
    path: "/tests/toast",
    component: () => import("/app/pages/tests/toast.vue")
  },
  {
    name: "tests-useCompany",
    path: "/tests/useCompany",
    component: () => import("/app/pages/tests/useCompany.vue")
  },
  {
    name: "tests-ws",
    path: "/tests/ws",
    component: () => import("/app/pages/tests/ws.vue")
  },
  {
    name: "tests-ws1",
    path: "/tests/ws1",
    component: () => import("/app/pages/tests/ws1.vue")
  },
  {
    name: "tests-wysiwg",
    path: "/tests/wysiwg",
    component: () => import("/app/pages/tests/wysiwg.vue")
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: index2HgavUPmh3Meta || {},
    component: () => import("/app/pages/welcome/index.vue")
  }
]