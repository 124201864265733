export const useSupportedLanguages = () => useState("languageArray", () => {
    const languages = ['ar','eu','bn','bg','ca','yue','cs','da','nl','en','fil','fi','fr','gl','de','el','gu', 'he','hi','hu','is','id','it','ja','kn','ko','lv','lt', 'ms','ml','cmn','mr','nb','pl','pt','pa','ro','ru', 'sr','sk','es','sv','ta','te','th','tr','uk','vi'];
    return languages
});
  
export const useCountryLanguages = () => useState("countryLanguages", () => {
    
    return {
        "US": [
            {
                "language" : "en",
                "languageCode" : "en-US",
                "voiceName": "en-US-Neural2-F",
                "isActive": true
            },
            {
                "language" : "es",
                "languageCode" : "es-US",
                "voiceName": "es-US-News-F"
            },
        ],
        "CA": [
            {
                "language" : "en",
                "languageCode" : "en-US",
                "voiceName": "en-US-Wavenet-C",
                "isActive": true
            },
            {
                "language" : "fr",
                "languageCode" : "fr-CA",
                "voiceName": "fr-CA-Wavenet-A",
                "isActive": true
            },
        ],
        "GB": [
            {
                "language" : "en",
                "languageCode" : "en-GB",
                "voiceName": "en-GB-News-H",
                "isActive": true
            }
        ],
        "AT": [
            {
                "language" : "de",
                "languageCode" : "de-DE",
                "voiceName": "de-DE-Wavenet-C",
                "isActive": true
            } 
        ],
        "BR": [
            {
                "language" : "pt",
                "languageCode" : "pt-BR",
                "voiceName": "pt-BR-Wavenet-A",
                "isActive": true
            } 
        ],
        "CZ": [
            {
                "language" : "cs",
                "languageCode" : "cs-CZ",
                "voiceName": "cs-CZ-Wavenet-A",
                "isActive": true
            } 
        ],
        "IL": [
            {
                "language" : "he",
                "languageCode" : "he-IL",
                "voiceName": "he-IL-Wavenet-C",
                "isActive": true
            } 
        ],
        "PR": [
            {
                "language" : "es",
                "languageCode" : "es-US",
                "voiceName": "es-US-Wavenet-A",
                "isActive": true
            },
            {
                "language" : "en",
                "languageCode" : "en-US",
                "voiceName": "en-US-Wavenet-C",
                "isActive": true
            }
        ]
    }
});