<template>
    <div>
        
        <div class="overlay-setting " :class="showPhoneWindow ? 'show':''" @click="showHidePhone"></div>
        <div class="app-settings" :class="showPhoneWindow ? 'active':''">
            <a href="javascript:;" id="settingbutton" class="setting" :class="(phoneStatus=='talking' || phoneStatus=='dialing' || phoneStatus=='ringing') ? 'animated': ''" :style="phoneStatus == 'talking' ? 'background: #a11abc;' : (uaRegistrationStatus == 'unregistered' ? 'background: #ec1313;' : (agentStatus == 'Available' ? 'background: #009b31;' : 'background: #f38b00'))" @click="showHidePhone" >
                <h5 class="mb-0"> 
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone-call" data-v-5522efca="">
                        <path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                    </svg>
                </h5>
            </a>

            <div class="ps sidbarchat">
                
                <!-- HEADER OF THE MODULE  -->
                <div class="phone-header p-3" style="background-color:#0045c42e">
                    <div class="row">
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 align-self-center">
                            <div v-if="uaRegistrationStatus == 'unregistered'">
                                <span class="badge badge-danger">OFFLINE</span>
                            </div>

                            <div v-if="uaRegistrationStatus!='unregistered'">
                                <span v-if="phoneStatus=='idle'" class="badge outline-badge-success me-1">ONLINE</span>
                                <span v-if="phoneStatus=='dialing'" class="badge outline-badge-warning me-1">Trying</span>
                                <span v-if="phoneStatus=='ringing'" class="badge outline-badge-secondary me-1">Ringing</span>
                                <span v-if="phoneStatus=='talking'" class="badge outline-badge-danger me-1">On Call</span>
                            </div>
                        </div>
                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8 order-sm-0 order-1 text-end">
                            <h4>{{user.firstName}} {{user.lastName}}</h4>
                        </div>
                    </div>
                    

                    

                    <div class="row">
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 text-start">
                            <span v-if="agentStatus=='Available'" class="badge badge-success me-1">Logged IN</span> 
                            <span v-if="agentStatus=='Logged Out'" class="badge badge-danger me-1">Logged OUT</span> 
                        </div>
                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8 order-sm-0 order-1 text-end">
                            <span class="badge badge-primary me-1"># {{user.extension}}</span>
                        </div>
                        
                    </div>

                <div class=" pt-4">
                    <label class="switch s-outline s-outline-warning mb-4 me-2 ">
                            <input v-model="dnd" type="checkbox" @change="toggleOnline" />
                            <span class="slider round"></span>
                            <label></label>
                        </label> 
                        DoNotDisturb<br>
                    <label class="switch s-outline s-outline-success mb-4 me-2 ">
                            <input v-model="isOnline" type="checkbox" @change="toggleOnline" />
                            <span class="slider round"></span>
                            <label></label>
                        </label> 
                        Online<br>
                    <label class="switch s-outline s-outline-primary mb-4 me-2 ">
                            <input v-model="autoanswer" type="checkbox" @change="updateAutoanswer" />
                            <span class="slider round"></span>
                            <label></label>
                        </label> 
                        Pickup Calls Automatically

                        
                    <div class="select2" v-if="cc_select_options.length>1" style="width:100%;height:50px"  >
                        <div class="input-group">
                            <span class="input-group-text" style="font-size:13px">
                                From: 
                            </span>    
                            <multiselect 
                                v-model="callcenter_used" 
                                :options="cc_select_options" 
                                select-first=true
                                placeholder="" selected-label="" select-label="" :allow-empty="false" :can-deselect="false"
                                deselect-label="" object="false" track-by="value" label="name" 
                                class="multiselect-blue form-control"
                                style="height:30px"
                                
                                >
                            </multiselect>
                        </div>
                        </div>
                    </div>    
                </div>
                
                
                <div v-if="eavsedropMode==true && phoneStatus=='talking'" >
                <!-- <div> -->
                    <!-- Eavsedropping ...  -->
                    <h5 class="text-center mt-3">You are connected to the call <br> of {{edObject.firstname}} {{edObject.lastname}}</h5>
                        <!--  -->
                    <div class="text-center" role="group" aria-label="Basic example">
                        <button type="button" class="btn mt-2" :disabled="edMode=='listen'" @click="changeEDStatus('listen')" :class="edMode=='listen'? 'btn-primary' : 'btn-outline-primary'" style="height:38px;width:90%">Listen only</button>
                        <button type="button" class="btn mt-2" :disabled="edMode=='wisper-agent'" @click="changeEDStatus('wisper-agent')" :class="edMode=='wisper-agent'? 'btn-primary' : 'btn-outline-primary'" style="height:38px;width:90%">Whisper Agent</button>
                        <button type="button" class="btn mt-2" :disabled="edMode=='wisper-customer'" @click="changeEDStatus('wisper-customer')" :class="edMode=='wisper-customer'? 'btn-primary' : 'btn-outline-primary'" style="height:38px;width:90%">Whisper Customer</button>
                        <button type="button" class="btn mt-2" :disabled="edMode=='speak-to-all'" @click="changeEDStatus('speak-to-all')" :class="edMode=='speak-to-all'? 'btn-primary' : 'btn-outline-primary'" style="height:38px;width:90%">Speak to All</button>
                    </div>
                    <div class="btn-group-toggle row mb-4 mx-0  mt-5 ms-4" >
                        <div class="text-color radio-primary col-12 ps-0 mb-3 custom-control custom-radio text-center" @click="cancel">
                            <button type="button" class="btn btn-danger btn-lg mb-2 me-2 rounded-circle">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone-off" data-v-5522efca=""><path d="M10.68 13.31a16 16 0 0 0 3.41 2.6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7 2 2 0 0 1 1.72 2v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.42 19.42 0 0 1-3.33-2.67m-2.67-3.34a19.79 19.79 0 0 1-3.07-8.63A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91"></path><line x1="23" y1="1" x2="1" y2="23"></line></svg>
                            </button>
                        </div>
                    </div>


                </div>

                <div v-if="eavsedropMode==false">

                    <div v-if="showOptionsScreen == false">
                    <!-- Outbound call screen -->
                        <div class="p-3" v-if="isInboundCallInProcess == false">
                            <!-- Dialer with call button -->
                            <phoneDialString v-model="destination_number">
                                <phoneDialpad v-if="phoneStatus == 'talking' " />
                            </phoneDialString>
                        </div>
                        
                        
                        <div v-if="isInboundCallInProcess">

                            <div class="media">
                                <div class="media-body mt-4 text-center">
                                    <h5 class="media-heading"><span class="media-title"> {{displayName}}</span></h5>
                                    <h5 class="media-heading"><span class="media-title"> {{callerId}}</span></h5>
                                </div>
                            </div>
                            <div class="btn-group-toggle row mb-4 mx-0  mt-5 ms-4" >
                                <div class="text-color radio-primary col-6 ps-0 mb-3 custom-control custom-radio text-center" v-if="phoneStatus=='idle'" :class="phoneStatus!='idle'? 'col-12' : 'col-6'">
                                    <button type="button" class="btn btn-success btn-lg mb-2 me-2 rounded-circle"  :class="{ 'flashing-button': isFlashing, 'color-change': isColorChanging }" :disabled="false" @click="answer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone-call" data-v-5522efca=""><path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                    </button>
                                </div>
                                <div class="text-color radio-primary col-6 ps-0 mb-3 custom-control custom-radio text-center" :class="phoneStatus!='idle' ? 'col-12' : 'col-6'" @click="reject">
                                    <button type="button" class="btn btn-danger btn-lg mb-2 me-2 rounded-circle">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone-off" data-v-5522efca=""><path d="M10.68 13.31a16 16 0 0 0 3.41 2.6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7 2 2 0 0 1 1.72 2v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.42 19.42 0 0 1-3.33-2.67m-2.67-3.34a19.79 19.79 0 0 1-3.07-8.63A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91"></path><line x1="23" y1="1" x2="1" y2="23"></line></svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="btn-group-toggle row ps-2">
                                <phoneToolbar/>
                        </div>
                        <div class="btn-group-toggle row ps-2">
                                <phoneAgentLogin/>
                        </div>

                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    <audio id="localAudio" muted="muted" autoplay></audio>
    <audio id="remoteAudio"  autoplay></audio>
</template>


<script setup>
    import { ref, onMounted } from 'vue';
    // import { notification } from "../serviceWorkers/notification"
    import { UserAgent, Inviter, SessionState, Registerer, UserAgentState, Web, RegistererState} from 'sip.js';
    // Log.setLevel(Log.Level.warn);
    const connection = { protocol: "wss", port: 9381, domain : "" }; // domain we get dynamically

    const agentStatus = useAgentStatus(); // Agent status, that indicates weather he is logged in or not
    const user = ref({}); // We keep all nessesary user data here to function
    const userData = useMyData(); // Shared storage that contain user data for connectivity
    const phoneStatus = usePhoneStatus(); // idle, dialing,  ... shared status so no one starts the call if it is not idling
    const phoneHoldStatus = usePhoneHoldStatus(); // hold, unhold
    const phoneMuteStatus = usePhoneMuteStatus();
    let userAgentOptions = {};
    let userAgent;
    let registerer;
    let extraHeaders = [];
    let uaIsInitialized = false;
    let isInboundCallInProcess = ref(false);
    const showOptionsScreen = ref(false)
    const autoanswer = ref(false);
    const callcenter_used = ref({});

    const isOnline = ref(true);
    const dnd = ref(false);
    const toggleOnline = async () => {
    if (isOnline.value) {
        // User wants to be online
        // Ensure the userAgent is started
        // if (!userAgent.isStarted()) {
            await userAgent.start();
        // }
        // Register the UA
        try {
            await registerer.register({
                requestOptions: {
                    extraHeaders: extraHeaders
                }
            });
        } catch (error) {
            console.error('Registration failed:', error);
        }
    } else {
        // User wants to be offline
        // Unregister the UA
        try {
            await registerer.unregister({
                all: true,
                requestOptions: {
                    extraHeaders: extraHeaders
                }
            });
        } catch (error) {
            console.error('Unregistration failed:', error);
        }
    }
};

    const eavsedropMode = ref(false);
    const edObject = ref({})
    const edMode = ref("listen")

    const cc_select_options = ref([]);
    const cc = ref([]); 
    const initial_selection_is_done = ref(false)

    const uaRegistrationStatus = ref("unregistered");
    const { currentRoute } = useRouter();

    let ringback, ringtone; 
    
    const dialCommand = useDialCommand(); // Here we get instructions to dial out;
    
    watch (() => dialCommand.value, (newValue) => {
       if (Object.keys(newValue).length>0){

            if (dialCommand.value.directive == "start") {
                makeCall(dialCommand.value.number);
                dialCommand.value = {};
            }
            if (dialCommand.value.directive == "stop") {
                cancel();
                dialCommand.value = {};
            }
            if (dialCommand.value.directive == "dtmf") {
                sendDTMF(dialCommand.value.digit);
                dialCommand.value = {};
            }
            if (dialCommand.value.directive == "hold") {
                hold();
                dialCommand.value = {};
                
            }

            if (dialCommand.value.directive == "listen") {
                if (phoneStatus.value == 'idle'){
                    makeCall('eavesdrop', [`P-UUID: ${dialCommand.value.intercept_uuid}`, `P-Socket: ${dialCommand.value.socket}`, `P-Calltype: ${dialCommand.value.calltype}` ])
                    edObject.value = {firstname:dialCommand.value.firstname, lastname:dialCommand.value.lastname, direction:dialCommand.value.direction}
                    eavsedropMode.value = true;
                    showPhoneWindow.value = true;
                } else {
                     alert("We can't connect to the agent's call because your line is busy");
                }
                dialCommand.value = {};
                
            }

            if (dialCommand.value.directive == "unhold") {
                unhold();
                dialCommand.value = {};
                
            }
            if (dialCommand.value.directive == "mute") {
                mute();
                dialCommand.value = {};
                
            }
            if (dialCommand.value.directive == "unmute") {
                unmute();
                dialCommand.value = {};
                
            }

            
       }
    }, {deep:true})

    watch (() => callcenter_used.value, async (newValue, oldValue) => {
        if (JSON.stringify(newValue) != JSON.stringify(oldValue)){
            if (Object.keys(oldValue).length>0){
                await $patch(`/api/protected/agent/default-number/${userData.value.agentId}`, {callcenter_id:newValue.value});
            } 
        };
    });
    
    const updateAutoanswer = async () => {
        await $patch(`/api/protected/agent/intercom/${userData.value.agentId}`, {intercom:autoanswer.value});
    }

    const changeEDStatus =  (status) => {
        switch (status) {
            case "listen":
                sendDTMF(0);    
            break;    
            case "wisper-agent":
                sendDTMF(1);  
            break;    
            case "wisper-customer":
                sendDTMF(2);  
            break;    
            case "speak-to-all":
                sendDTMF(3);  
            break;    
        }
        
        edMode.value = status;
    }
    
    onMounted(async () => {
        const response = await $get('/api/protected/my/data');
        user.value = response.data;
        userData.value = user.value;
        connection.domain = user.value.domain;
        connection.token = user.value.token;
        connection.agentId = user.value.agentId;
        autoanswer.value = user.value.intercom;

        startSipClient(connection);
        
        ringback = new Audio('/ringback.mp3');
        ringtone =  new Audio('/gio_resotone.mp3');
        
        // window.addEventListener("beforeunload", handleBeforeUnload);

        if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
            navigator.serviceWorker.register('/sw-cc.js')
            .then((registration) => {
                console.log('Service Worker registered with scope:', registration.scope);
                serviceWorkerRegistration.value = registration;
                // serviceWorkerRegistration.value = registration;
                sendRouteToServiceWorker(registration, currentRoute.value.fullPath);

                watch(currentRoute, (newRoute) => {
                    sendRouteToServiceWorker(registration, newRoute.fullPath);
                });
            })
            .catch((error) => {
                console.error('Service Worker registration failed:', error);
            });

            navigator.serviceWorker.addEventListener('message', (event) => {
                const data = event.data;
                if (data && data.action) {
                    if (data.action === 'accept') {
                        answer();
                    } else if (data.action === 'reject') {
                        reject();
                    }
                }
            });
        }

        // const response3 = await $get(`/api/protected/agent/participation/${userData.value.agentId}`);
        // const arrayOfCallcenterSipAccountIsParticipated = response3.data;
    
        // callcenters.value = (await $get(`/api/protected/callcenter`)).data;
        // cc_select_options.value = [];
        let callcenterUsers = (await $get(`/api/protected/callcenter-users/${userData.value.agentId}`)).data;
        for (let callcenterUser of callcenterUsers){
            cc_select_options.value.push({name: callcenterUser.callcenter_id.name, value:callcenterUser.callcenter_id._id})
            if (callcenterUser.is_default){
                callcenter_used.value = {name: callcenterUser.callcenter_id.name, value:callcenterUser.callcenter_id._id};
                initial_selection_is_done.value = true;
            }
            // callcenterUser  
        }

        // for (let item of cc.value) {
        //     if (arrayOfCallcenterSipAccountIsParticipated.includes(item._id)){
        //         cc_select_options.value.push({name: item.name, value:item._id})
        //         if (callcenter_used.value == "")  callcenter_used.value = {name: item.name, value:item._id};
        //     }
        // }
        // alert(cc_select_options.value);
    });

    
        function sendRouteToServiceWorker(registration, path) {
            if (registration.active) {
                registration.active.postMessage({ type: 'INIT', payload: path });
                console.log('[Main Thread] Message sent to Service Worker with path:', path);
            }
        }


    
    // import ringtoneFile from '/gio_resotone.mp3'; 
    import waitingFile from '/waiting.mp3'; 
    
    import Multiselect from '@suadelabs/vue3-multiselect';
    import '@suadelabs/vue3-multiselect/dist/vue3-multiselect.css';
    import '~/assets/css/select2.css';

    const protocol = "wss"; // wss
    const {$swal, $get, $patch} = useNuxtApp();

    let token;
    let username;
    let password;
    
    
    
    let show_active_call_toolbar = ref(false);
    let show_active_call_keypad = ref(false);
    let incomingSession;
    let inviter;

    let ongoingSession;
    let xfer_mode = ref(false);

    const destination_number = ref("")
    const destination_number2 = ref("");
    const xfer_extension = ref();
    const onHold = ref(false)

    let uri;

    const isFlashing = ref(false);
    const isColorChanging = ref(false);
    let invitationObject;
    let registeredOnce = false; // Controls, wether we showed dialog onec or not. This is requitred, because layer can't play audio without user interaction with document first.
    
    
    const serviceWorkerRegistration = ref(null);

    let cancelIncomingButtonDisabled = ref(false);
    let acceptIncomingButtonDisabled = ref(false);
    
    let waitingback;

    let callerId = ref("");
    let displayName = ref("");
    
    

    const playRingtone = ()  => {
        ringtone.play();
        ringtone.currentTime = 0;
        ringtone.loop = true;
    }
    const pauseRingtone = ()  => {
        ringtone.pause();
    }

    const playRingback = ()  => {
        ringback.play();
        ringback.currentTime = 0;
        ringback.loop = true;

    }
    const pauseRingback = ()  => {
        ringback.pause();
    }

    function answer() {
        if (invitationObject && invitationObject.state === SessionState.Initial) {
            invitationObject.accept().catch(error => console.log("Accept Error:", error));
            isFlashing.value = false;
            isColorChanging.value = false;
        } else {
            console.warn("Cannot answer, invalid session state:", invitationObject?.state);
        }
    }

    const reject = () => {
        if (invitationObject) {
            console.log("Current phone status:", phoneStatus.value);
            
            // Pause the ringtone before any action
            pauseRingtone();

            // Check session state and call appropriate methods based on phoneStatus
            if (phoneStatus.value === "ringing" || phoneStatus.value === "idle") {
                if (invitationObject.state === SessionState.Initial || invitationObject.state === SessionState.Establishing) {
                    invitationObject.reject().catch(error => console.log("Reject Error:", error));
                } else {
                    console.warn("Cannot reject; invalid session state:", invitationObject.state);
                }
            }
            
            if (phoneStatus.value === "talking") {
                if (invitationObject.state === SessionState.Established) {
                    invitationObject.bye().catch(error => console.log("Bye Error:", error));
                } else {
                    console.warn("Cannot end call; invalid session state:", invitationObject.state);
                }
            }
        }
    };

    // const answer = () => {
    //     invitationObject.accept();
    //     isFlashing.value = false;
    //     isColorChanging.value = false;
    // }

    //Incoming call reject
    // const reject = () => {
        
    //     if (invitationObject) {
    //         console.log("---", phoneStatus.value)
    //         if (phoneStatus.value=="ringing" || phoneStatus.value=="idle"){
    //             pauseRingtone();
    //             invitationObject.reject();
    //         }
    //         if (phoneStatus.value=="talking"){
    //             invitationObject.bye();
    //         }
    //     }
    // }



    // Incom
    const hangup = () => {
        if (invitationObject) {
            invitationObject.bye();
        }
        
        console.log("Call terminated");
    }
    function startSipClient (connection){
        
        extraHeaders = [`JWT: ${connection.token}`];
        let uri = UserAgent.makeURI(`sip:${connection.agentId}@${connection.domain}`);
        const transportOptions = { 
            server: `${connection.protocol}://${connection.domain}:${connection.port}`, 
                connectionTimeout: 3,
            };
            // userAgentOptions.log.setLevel(0); // = {level : 0, builtinEnabled: false};
            // userAgentOptions.logLevel = "error";
            userAgentOptions = { transportOptions, uri, logLevel: "error"};
            userAgentOptions.authorizationUsername = `${user.value.firstName} ${user.value.lastName}`,
            userAgentOptions.authorizationPassword = ""; // No password, we use jwt
            userAgentOptions.delegate = {
                onInvite: (invitation) => {
                    // notifyMe();
                    
                    invitationObject = invitation;
                    isInboundCallInProcess.value = true;
                    callerId.value = invitation.incomingInviteRequest.message.from.uri.normal.user;
                    displayName.value = invitation.incomingInviteRequest.message.from._displayName;
                    notifyUser(  `${displayName.value} (${callerId.value})` );
                    if (autoanswer.value == true){
                        setTimeout(()=>{
                            answer()
                        },2000);
                    }
                    
                    invitation.stateChange.addListener((state) => {
                       
                    switch (state) {

                        case "Initial":
                            
                            playRingtone();
                            break;
                            
                        case "Establishing":
                            phoneStatus.value = "ringing";
                            break;
                        case "Established":
                            hideNotification();
                            phoneStatus.value = "talking";
                            handleMedia(invitation);
                            pauseRingtone();
                            break;
                        case "Terminating":
                            pauseRingtone();
                            isInboundCallInProcess.value = false;
                            phoneStatus.value = "idle";
                        case "Terminated":
                            hideNotification();
                            pauseRingtone();    
                            isInboundCallInProcess.value = false;
                            phoneStatus.value = "idle";
                            phoneHoldStatus.value = "unhold";
                            phoneMuteStatus.value = "unmute";
                            eavsedropMode.value = false;
                            
                            break;
                        default:
                            throw new Error("Unknown session state.");
                    }
                });
                let constrainsDefault = {
                    audio: true,
                    video: false,
                    }

                playRingtone();
                isFlashing.value = true;
                isColorChanging.value = true;
                showPhoneWindow.value = true;
                }
            }
            
           
            userAgent = new UserAgent(userAgentOptions);
                    
            registerer = new Registerer(userAgent,  {extraHeaders});
            userAgent.start();
            

            
               
            userAgent.transport.stateChange.addListener((state) => {
                if (userAgent.transport.isConnected()) {
                
                try {
                    registerer.register({ extraHeaders: ['X-Header: foo']});
                } catch(error){
                    uaRegistrationStatus.value = "unregistered"
                    console.log("Registration error");
                    console.log(error);
                }

                } else {
                    uaRegistrationStatus.value = "unregistered"
                    startSipClient(connection);
                }
            });
            

            registerer.stateChange.addListener((newState) => {
                switch (newState) {
                    case RegistererState.Registered:
                    console.log("Registered successfully.");
                    uaRegistrationStatus.value = "registered"
                    break;
                    case RegistererState.Unregistered:
                    // console.log("Unregistered successfully.");
                    uaRegistrationStatus.value = "unregistered"
                    break;
                    case RegistererState.Terminated:
                    console.log("Registration terminated.");
                    break;
                }
            });
            
            registerer.stateChange.addListener((state) => {
                if (state == "Registered"){
                    uaRegistrationStatus.value = "registered";
                    if (!registeredOnce){
                        registeredOnce = true;
                    }
                }
                if (state == "Unregistered"){
                    uaRegistrationStatus.value = "unregistered"
                    // registerer.register();
                }
            })

    }

    const sendDTMF = (n) => {
        const options = {
            requestOptions: {
                body: {
                contentDisposition: "render",
                contentType: "application/dtmf-relay",
                content: `Signal=${n}\r\nDuration=1000`
                }
            }
        };
        ongoingSession.info(options);
    }

    const makeCall = (number, additionalHeaders =[] ) => {
        if (!userAgent) {
            console.error('User Agent is not initialized');
            return;
        }
        // current_call_direction = "outgoing"
        const target = UserAgent.makeURI(`sip:${number}@${user.value.domain}:${connection.port}`);
        destination_number.value = number;
        const inviteOptions = {
            extraHeaders: [
                `JWT: ${user.value.token}`
            ],
            earlyMedia: true,
        }
        for (let header of additionalHeaders){
            inviteOptions.extraHeaders.push(header) 
        }
        
        inviter = new Inviter(userAgent, target, inviteOptions);



        inviter.stateChange.addListener((newState) => {
            switch (newState) {
                case SessionState.Establishing:
                    let earlyMediaDetected = false;
                    phoneStatus.value = "ringing";

                    const ringbackTimeout = setTimeout(() => {
                        if (!earlyMediaDetected) {
                            // console.log("No early media detected, playing ringback");
                            if ( phoneStatus.value == "ringing" ) playRingback();
                        }
                    }, 2000);

                    if (inviter.sessionDescriptionHandler && inviter.sessionDescriptionHandler.peerConnection) {
                        const peerConnection = inviter.sessionDescriptionHandler.peerConnection;
                        
                        peerConnection.ontrack = (event) => {
                            earlyMediaDetected = true;
                            clearTimeout(ringbackTimeout);
                            // Eary media detected;

                            // Directly attach the incoming track to an audio element
                            let audioElement = document.getElementById("remoteAudio");
                            if (!audioElement) {
                                audioElement = document.createElement("audio");
                                audioElement.id = "remoteAudio";
                                audioElement.autoplay = true;
                                document.body.appendChild(audioElement);
                            }

                            const [stream] = event.streams;
                            audioElement.srcObject = stream;

                            // This function call can remain minimal for any other media setup
                            handleMedia(inviter);
                        };
                    }
                break;
                    
                case SessionState.Established:
                    show_active_call_toolbar.value=true;
                    phoneStatus.value = "talking";
                    pauseRingback();
                    handleMedia(inviter);
                break;
                
                case SessionState.Terminated:
                    phoneStatus.value = "idle";
                    pauseRingback();
                    phoneHoldStatus.value = "unhold";
                    phoneMuteStatus.value = "unmute";
                    eavsedropMode.value = false;
                    destination_number.value ="";
                    setTimeout(() => {
                        showPhoneWindow.value = false;
                    }, 2000);
                break;
                
                default:
                break;
            }
        });
        

        inviter.invite();
    }

  
    
    
    const cancel = () => {
        if (phoneStatus.value == "ringing"){
            if (inviter) {
                inviter.cancel();   
            }
        }
        if (phoneStatus.value == "talking"){
            if (inviter) {
                inviter.bye();   
            }
        }
    }

    const hold = () => {
        if (process.client){
            const options = {
                sessionDescriptionHandlerModifiers: [Web.holdModifier]
            }
            ongoingSession.invite(options);
            phoneHoldStatus.value = "hold";
        }
        onHold.value=true;
    }

    const unhold = () => {
        if (process.client){
            const options = {
                sessionDescriptionHandlerModifiers: []
            }
            ongoingSession.invite(options);
        }
        phoneHoldStatus.value = "unhold";
        
    }
    const unmute = () => {
        if (ongoingSession && ongoingSession.sessionDescriptionHandler) {
            const pc = ongoingSession.sessionDescriptionHandler.peerConnection;
                pc.getSenders().forEach(sender => {
                    if (sender.track && sender.track.kind === 'audio') {
                    sender.track.enabled = true;  // Flip the mute state
                    phoneMuteStatus.value = "unmute";
                }
            });
        }
    }
    
    const mute = () => {
        if (ongoingSession && ongoingSession.sessionDescriptionHandler) {
        const pc = ongoingSession.sessionDescriptionHandler.peerConnection;
            pc.getSenders().forEach(sender => {
                if (sender.track && sender.track.kind === 'audio') {
                    sender.track.enabled = false;  // Flip the mute state
                    phoneMuteStatus.value = "mute";
                }
            });
        }
    }

    // Toggle mute/unmute


    const sleep = async(n) => {
        return new Promise((resolve)=>{
            setTimeout(()=>{
                resolve()
            }, n*1000);
        })
    }


    // Handle media in the main thread
    function handleMedia(session) {
        ongoingSession = session;
        const sessionDescriptionHandler = session.sessionDescriptionHandler;
        if (sessionDescriptionHandler) {
        const localStream = sessionDescriptionHandler.localMediaStream;
        const remoteStream = sessionDescriptionHandler.remoteMediaStream;
    
        nextTick(() => {
            if (isInboundCallInProcess.value == true){
                const localAudioElement = document.getElementById('localAudio');
                const remoteAudioElement = document.getElementById('remoteAudio');
                if (localAudioElement && remoteAudioElement) {
                    localAudioElement.srcObject = localStream;
                    remoteAudioElement.srcObject = remoteStream;
                } else {
                console.error('Video elements not found in the DOM');
                }
            }
        });
        }
    }



    // function cleanupMedia() {
    //     mediaElement.srcObject = null;
    //     mediaElement.pause();
    // }


  
    const handleBeforeUnload = (event) => {
        // Cancel the event
        const confirmationMessage = "Are you sure you want to leave this page? Your changes may not be saved.";
        
        if (!window.confirm(confirmationMessage)) {
            event.preventDefault();
            // Chrome requires returnValue to be set
            event.returnValue = confirmationMessage;
            confirmationMessage;
        }
    };

    onBeforeUnmount(() => {
    window.removeEventListener("beforeunload", handleBeforeUnload);
    });


    const extensions = ref([]);
    let myAgentId;

    
    const agents = ref([]); //useInternalPhonebook();
    const ph = ref([]);
    
    

    const showHidePhone = () => {
        if (showPhoneWindow.value == false) {
            showPhoneWindow.value = true
        } else {
            showPhoneWindow.value = false
        }
    }

    const showPhoneWindow = useShowPhoneWindow(); //ref(false)
    const status = ref("unregistered")

    function requestNotificationPermission() {
        if (Notification.permission === 'default') {
            Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
                console.log('Notification permission granted.');
            }
            });
        }
    }
    requestNotificationPermission();

if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
    // const currentRoute = router.currentRoute;
    navigator.serviceWorker.register('/sw-cc.js')
    .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
        // navigator.serviceWorker.oncontrollerchange = function() {
            if (registration.active) {
                
            registration.active.postMessage({ type: 'INIT', payload: currentRoute.value.fullPath });
            console.log('[Main Thread] Message sent to Service Worker');
            
        } else {
            alert("NOT ACTIVE!!!")
        }
        // };
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    });

  navigator.serviceWorker.addEventListener('message', (event) => {
    const data = event.data;
    if (data && data.action) {
      if (data.action === 'accept') {
        acceptCall();
      } else if (data.action === 'reject') {
        rejectCall();
      }
    }
  });
}

let activeNotificationTag = null;

function showNotification(title, options) {
  if (serviceWorkerRegistration.value) {
    serviceWorkerRegistration.value.showNotification(title, options)
      .then(() => {
        activeNotificationTag = options.tag; // Save the tag for later use
      })
      .catch(error => {
        console.error('Error showing notification:', error);
      });
  } else {
    console.error('Service Worker registration not available');
  }
}

function hideNotification() {
  if (serviceWorkerRegistration.value) {
    serviceWorkerRegistration.value.getNotifications({ activeNotificationTag })
      .then(notifications => {
        notifications.forEach(notification => notification.close());
      })
      .catch(error => {
        console.error('Error closing notification:', error);
      });
  }
}

function notifyUser(from) {
    let uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2);
    const options = {
        body: `Call from ${from}.`,
        icon: '/favicon.ico',
        tag: uniqueId,
        actions: [
        { action: 'accept', title: 'Accept' },
        { action: 'reject', title: 'Reject' }
        ]
    };
    showNotification(`Incoming Call from ${from}`, options);
}


function acceptCall() {
  // Your logic to handle accept call
  console.log('Call accepted');
  answer();
}

function rejectCall() {
  // Your logic to handle reject call
  console.log('Call rejected');
  reject();
}

window.addEventListener('focus', function() {
  console.log('Tab is focused');
  // Perform session-related tasks here
});

</script>
<!-- // Assuming `session` is your current active session
let heldSession = session; // Save the current session
session.hold(); // Put the current session on hold

// Create a new session for the transfer target
let targetUri = UserAgent.makeURI('sip:target@example.com');
let inviterOptions = {
  // Define media constraints, extra headers, etc.
};
let newSession = new Inviter(userAgent, targetUri, inviterOptions);

// Once the new session is established and accepted
newSession.invite().then(() => {
  // Refer the held session to the new session
  heldSession.refer(newSession);
}); -->

<style>
.btn.rounded-circle {
    height: 65px;
    width: 65px;
    padding: 8px 8px;
}

.flashing-button {
  animation: pulse 0.3s infinite alternate; /* 1s duration, infinite loop, alternate direction */
}

.color-change {
  background-color: green; /* Initial color */
  transition: background-color 0.3s ease; /* Smooth transition */
}

@keyframes pulse {
  from {
    opacity: 1; /* Start with full opacity */
  }
  to {
    opacity: 0.7; /* Fade to half opacity */
  }
}
</style>

<style >
.multiselect__tags {
    height: 54px;
    padding: 16px 40px 0 8px;
    /* border-radius: 0px; */
    /* border: 0px; */
}

.form-control {
    letter-spacing: 1px;
    padding: 0.00rem 0.5rem;
}
/* .multiselect__tags {
    border-radius: 5px;
    border: 0px solid;
    padding: 16px 0px;
    border: 0px solid 
} */
.btn {
    height: 54px;
}

.multiselect--disabled .multiselect__current, .multiselect--disabled .multiselect__select {
    background: #ededed;
    color: #a6a6a6;
    height: 53px;
}

.bottom-div {
  position: absolute;
  bottom: 0;
  left:20px
  
}

</style>

<style scoped>
::v-deep(.multiselect__tags)  {
    height: 32px;
    padding: 11px 40px 0 8px;
    /* border-radius: 0px; */
    border: 0px;
}
::v-deep(.multiselect__tags:hover)  {
    height: 32px;
    padding: 11px 40px 0 8px;
    /* border-radius: 0px; */
    border: 0px;
}
::v-deep(.multiselect__select) {
    line-height: 0px;
}

::v-deep(.multiselect__single) {
    font-family: inherit;
    font-size: 13px;
    touch-action: manipulation;
}

.setting h5 {
    /* animation: spin 4s linear infinite; */
    animation: none; 
    color: #fff;
}
.animated h5 {
    animation: spin 4s linear infinite;
}
</style>
