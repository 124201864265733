<template>
    <div class="row">
        <div class="mb-2 col ">
            <label for="firstName" class="form-label">First Name<span class="text-danger">*</span></label>
            <input type="text" class="form-control" autocomplete="off" v-model="contact.firstName" required />
        </div>
        <div class="mb-2  col" >
            <label for="lastName" class="form-label">Last Name<span class="text-danger">*</span></label>
            <input type="text" class="form-control" autocomplete="off" v-model="contact.lastName" required />
        </div>
    </div>
    <!-- contact:: {{contact}}<br><br> -->
    <!-- contactSettings:: {{contactSettings}} --> 


    <div class="row mt-4" v-for="(phone, index) in contact.phones" :key="index">
        <!-- <div class="mb-2 col-1">
        </div> -->
        <div class="col-5">
            <label for="phone" class="form-label">Phone</label><span class="text-danger">*</span>
            <phoneInput v-model="contact.phones[index].phone" style="height:54px; z-index:0" />
        </div>

        <div class="col">
            <label class="form-check-label">Hint</label>
            <input type="text" class="form-control" v-model="phone.hint" />
            <!-- <selectBox v-model="contact.phones[index].allowCalls" :options="isContactPhone"/> -->
        </div> 
        <!-- <div class="mb-2 mt-3  col" v-if="contactSettings.showAllowTextSelector">
            <label class="form-check-label">Allow Text</label>
            <! -- <input type="text" class="form-control" v-model="phone.type" /> -- >
            <selectBox v-model="contact.phones[index].allowText" :options="isContactPhone"/>
        </div> -->
        <div class="col-3 text-end">
            <label for="phone" class="form-label">&nbsp<br> </label>
            <div>
                <button class="btn btn-lg btn-outline-dark" style="min-height:54px" @click="removePhone(index)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2">
                        <polyline points="3 6 5 6 21 6"></polyline>
                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                        <line x1="10" y1="11" x2="10" y2="17"></line>
                        <line x1="14" y1="11" x2="14" y2="17"></line>
                    </svg>
                </button>
                <button v-if="index == contact.phones.length -1" class="btn btn-lg btn-outline-primary ms-2" style="min-height:54px" @click="addPhone">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus" data-v-5522efca=""><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                </button>
                <button v-else disabled class="btn btn-lg btn-outline-primary ms-2" style="min-height:54px" @click="addPhone">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus" data-v-5522efca=""><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                </button>
            </div>
        </div>
    </div>
    <div class="text-end mt-4" v-if="contact.phones.length==0">
        <button class="btn btn-outline-primary" style="min-height:54px" @click="addPhone"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus" data-v-5522efca=""><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>Add Phone</button>
    </div>

    <div class="mt-5" v-if="contactSettings && contactSettings.email && contactSettings.email.show">
        <div class="row" v-for="(item, index) in contact.emails" :key="index" >
            <div :class="contact.emails.length>1 ? 'col-5' : 'col-5'"  >
                <label for="email" class="form-label">Email Address <span v-if="contactSettings.email.required" class="text-danger">*</span></label>
                <emailInput v-model="contact.emails[index].email"  style="height:40px;width:100%;z-index:0" />
            </div>
            <div class="col-4">
                <label for="email" class="form-label">&nbsp</label>
                <input
                    type="text"
                    class="form-control"
                    id="email"
                    v-model="item.hint"
                />
                <small class="form-text text-muted">e.g: Sales, Customer Service </small>
            </div>
            <div class="text-end" :class="contact.emails.length>1 ? 'col-3' : 'col-3'">
                <label for="phone" class="form-label">&nbsp<br> </label>
                <div>
                    <button  :disabled="contact.emails.length==1" class="btn btn-lg btn-outline-dark" style="min-height:54px" @click="contact.emails.splice(index, 1);">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2">
                            <polyline points="3 6 5 6 21 6"></polyline>
                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                            <line x1="10" y1="11" x2="10" y2="17"></line>
                            <line x1="14" y1="11" x2="14" y2="17"></line>
                        </svg>
                    </button>
                    <button 
                        :disabled="!item.email.isValid || index!=contact.emails.length-1" 
                        class="btn btn-lg btn-outline-primary ms-2" 
                        style="min-height:54px" 
                        @click="contact.emails.push({email:{address:'', isValid:false} })"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus" data-v-5522efca=""><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
    
    
    <div class="row mt-3" v-if="contactSettings && (contactSettings.language || contactSettings.position ) && (contactSettings.language.show || contactSettings.position.show)" >
        <!-- HEY -->
        <div class="mb-2 col" v-if="contactSettings.position.show">
            <label for="position" class="form-label">Position<span v-if="contactSettings.position.required" class="text-danger">*</span></label>
            <input type="text" class="form-control" autocomplete="off" v-model="contact.position" />
        </div>
        
        <div v-if="contactSettings.language.show"  :class="contactSettings.language.show? 'mb-2 col-sm-12 col-md-6 col-lg-6' :  'mb-2 col-sm-12 col-md-12 col-lg-12' ">
            <label for="email" class="form-label">Language<span v-if="contactSettings.language.required" class="text-danger">*</span></label>
            <LanguagesLanguageSelector v-model="contact.language" />
            <!-- <input type="email" class="form-control" autocomplete="off" v-model="contact.language" /> -->
        </div>
       
    </div>
    <!-- {{contactSettings}} -->
    <div class="row mt-3" v-if="contactSettings && (contactSettings.gender || contactSettings.birthdate) && (contactSettings.gender.show || contactSettings.birthdate.show)">
        <div v-if="contactSettings.gender.show" class="mb-2 col-12">
            <label for="email" class="form-label">Gender<span v-if="contactSettings.gender.required" class="text-danger">*</span></label>
            <gender v-model="contact.genderData" />
        </div>
        
    </div>

    
    <div class="mt-3 mb-4" v-if="contactSettings.birthdate && contactSettings.birthdate.show">
        <label class="form-label">Birthday<span v-if="contactSettings.birthdate.required" class="text-danger">*</span></label>
        <BirthDate v-model="contact.birthdate"/>
    </div>

    <!-- <label class="mt-4 form-label">Phone Numbers</label> -->
    
    
    <div class="mb-3 row" v-for="field of contactSettings.customFields">
        <div class="pt-3 col" v-if="field.type === 'select'">
            <label :for="field._id" class="form-label">{{ field.title }}<span v-if="field.required" class="text-danger">*</span></label> 
            <selectBox  v-model="contact.customFields[field._id]" :options = "field.options"/>
            <small class="form-text text-muted">{{ field.hint }}</small>
        </div>
        <div class="pt-3 col" v-if="field.type === 'text'" >
            <div>
                <label :for="field._id" class="form-label">{{ field.title }}<span v-if="field.required" class="text-danger">*</span></label>
                    
                <input type="text" v-model="contact.customFields[field._id]" class="form-control" :id="field._id" :name="field._id" required>
                <small class="form-text text-muted">{{ field.hint }}</small>
            </div>
        </div>
    </div>
    <div class="mb-3 row" v-if="number_of_callcenters>1 && props.isNew==true">
        <label class="form-label">Call Center<span class="text-danger">*</span></label>
        <div v-if="props.showCallcenters">
            <SelectTags v-model="contact.callcenter_ids" :options="callcenter_filter_options" />
        </div>
    </div>
    
</template>

<script setup>

import { ref, watch, computed } from 'vue';
const {$parseJwt} = useNuxtApp();
const props = defineProps({
  modelValue: { type: Object, default: {} },
  showCallcenters: { type: Boolean, default: false },
  isNew: { type: Boolean, default: false }
});

const toId = (str) => str.toLowerCase().replace(/[^a-z0-9]/g, '_');

const contactSettings = useCRMContactSettings()

const emit = defineEmits(['update:modelValue']);

const contact = ref(props.modelValue);
contact.value.customFields = {};


watch (() => props.modelValue, (newValue) => {
    contact.value = newValue;
    if (!contact.callcenter_id) {
        contact.value.callcenter_id = defaultCallcenter.value;
    }
});


// Ensure phones are initialized with a default structure if needed
if (!contact.value.phones || !Array.isArray(contact.value.phones)) {
  contact.value.phones = [];
}


const genders = ref([{name:'Male', value:"male"}, {name:'Female', value:'female'}]);
// Methods
const addPhone = () => {
  contact.value.phones.push({phone: { number: '', isValid: false}, hint:""});
};

const removePhone = (index) => {
  contact.value.phones.splice(index, 1);
};

const isContactPhone = ref([
  { name: 'Yes', value: true },
  { name: 'No', value: false },
]);

function buildDateObject() {
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 100;

  const dateObject = {};

  for (let year = startYear; year <= currentYear; year++) {
    dateObject[year] = {}; // Initialize the year object

    for (let month = 1; month <= 12; month++) {
      const daysInMonth = new Date(year, month, 0).getDate(); // Get days in the current month
      dateObject[year][month] = Array.from({ length: daysInMonth }, (_, i) => i + 1); // Populate days
    }
  }

  return dateObject;
}

// Example usage:
const dateData = buildDateObject();

const userData = $parseJwt(sessionStorage.getItem('token'));
const account_id = sessionStorage.getItem('account_id');
const number_of_callcenters = ref(0);
const callcenter_filter_options = ref([]);
const defaultCallcenter = ref("")
    for (let item of userData.membership) {
        
        if (account_id == item.account_id) {
            number_of_callcenters.value = item.subroles.length;
            for (let subrole of item.subroles) {
                callcenter_filter_options.value.push({name:subrole.name, value:subrole.callcenter_id});
            }
            defaultCallcenter.value = callcenter_filter_options.value[0].value;
            contact.value.callcenter_id = defaultCallcenter.value;
        }
    }


</script>

<style scoped>
::v-deep(.select2 .multiselect__tag) {
    color: #ffffff;
    background: #0045c4;
}
::v-deep(.select2 .multiselect__tag-icon:after) {
    color: #ffffff !important;
}
</style>