<template>
    <div class="row">
      <div :class="localModel.selectedGender == 'other'? 'col-6':'col-12'">
        <!-- <label for="gender" class="form-label">Gender</label> -->
        <selectBox 
            v-model="localModel.selectedGender" 
            :options="[{ value: '', name: 'Choose an option' }, { value:'male', name:'Male' }, { value:'female', name:'Female' }, { value:'non-binary', name:'Non-binary' }, { value:'prefer-not-to-say', name:'Prefer not to say' }, { value:'other', name:'Other' }]"
        />
      </div>
      <div v-if="localModel.selectedGender == 'other'" class="col-6">
        <input
          type="text"
          class="form-control"
          id="otherGender"
          v-model="localModel.otherGender"
          placeholder="Enter gender"
        />
        <small for="otherGender" class="form-label">Please specify</small>
      </div>
    </div>
  </template>
  
  <script setup>
  
  // Props for v-model
  
  const props = defineProps({
    modelValue: {
      type: Object,
      default: () => ({
        selectedGender: '',
        otherGender: '',
      }),
    },
  });
  

    const emit = defineEmits(['update:modelValue']);
    const localModel = ref({});

  watch(
    localModel,
    (newValue) => {
      emit('update:modelValue', { ...newValue });
    },
    { deep: true }
  );

  watch(
    () => props.modelValue,
    (newValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(localModel.value)) {
            localModel.value = { ...newValue };
        }
    //   localModel.value = { ...newValue };
    },
    { deep: true, immediate: true }
  );

  </script>
  
  <style scoped>
  /* Add any custom styles here */
  </style>
  