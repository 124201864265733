<template>
    <div>
      <div class="modal fade" :id="modalId" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ props.title }}</h5>
              <button type="button" class="btn-close" @click="closeModal"></button>
            </div>
            <div class="modal-body">
              <slot></slot>
            </div>
            <div class="modal-footer">
              <!-- <button type="button" class="btn btn-outline-primary" @click="closeModal">Close</button> -->
              
              
              <div class="text-start">
                <button type="button" v-if="props.showDeleteButton==true"  class="btn btn-outline-danger" @click="remove" >Delete</button>
              </div>
              <button type="button" v-if="props.showCancelButton==true"  class="btn btn-outline-default" @click="cancel" >Cancel</button>
              <button type="button" v-if="props.showCloseButton==true"  class="btn btn-outline-primary" @click="close" >Close</button>
              <button type="button" v-if="props.showAddButton==true"  class="btn btn-outline-primary" @click="add" >Add</button>
              <button type="button" v-if="props.showUpdateButton==true"  class="btn btn-outline-primary" @click="update" >Update</button>
              <button type="button" v-if="props.showCustomButton==true"  class="btn btn-outline-primary" @click="custom" >{{props.customButtonCaption}}</button>
              <!-- <button type="button" v-if="props.mode=='update'" class="btn btn-primary" @click="update">Update</button> -->
              <button type="button" v-if="props.mode=='approval'" class="btn btn-primary" @click="approve">Approve</button>
              <button type="button" v-if="props.mode=='wizard'" :disabled="props.backDisabled" class="btn btn-outline-default" @click="back">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left" data-v-5522efca=""><polyline points="15 18 9 12 15 6"></polyline></svg>
                Back
              </button>
              <button type="button" v-if="props.mode=='create'|| props.showCreateButton==true" :disabled="props.createDisabled" class="btn btn-primary" @click="create">Create</button>
              
              <button type="button" v-if="props.mode=='wizard' && props.showNextButton==true" :disabled="props.nextDisabled" class="btn btn-outline-primary" @click="next">
                Next
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right" data-v-5522efca=""><polyline points="9 18 15 12 9 6"></polyline></svg>
              </button>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <style>
.fade-scale {
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all .25s linear;
  -o-transition: all .25s linear;
  transition: all .25s linear;
}

.fade-scale.in {
  opacity: 1;
  transform: scale(1);
}
</style>

  <script setup>
    
    const {$bootstrap} = useNuxtApp();
    let state = "closed";
    

    const props = defineProps({
        title: {type:String, default:''},
        reference:  {type:String, default:"myModal"},        
        mode: {type:String, default:""},
        showDeleteButton:  {type:Boolean, default: false},
        showCreateButton:  {type:Boolean, default: false},
        showAddButton:  {type:Boolean, default: false},
        showUpdateButton:  {type:Boolean, default: false},
        showNextButton:  {type:Boolean, default: false},
        showCloseButton :  {type:Boolean, default: false},
        showCancelButton :  {type:Boolean, default: false},
        showCustomButton :  {type:Boolean, default: false},
        createDisabled:  {type:Boolean, defult: false},
        nextDisabled: {type:Boolean, default: false},
        backDisabled: {type:Boolean, default: false},
        customButtonCaption: {type:String, default:"custom"},
        onUpdate: Function,
        onCreate: Function,
        onDelete: Function,
        onClosed: Function,
        onClose: Function,
        onCancel:Function,
        onAdd: Function,
        onApprove:Function,
        onNext:Function,
        onBack:Function,
        onCustomButtonClick: Function
    });

    let modal = null;

    let title = ref(props.title)
    let mode = ref(props.mode)
    let showDeleteButton = ref(props.showDeleteButton);
    let createDisabled = ref(props.createDisabled);
    
    const id = getRandomId(9, 8);
    const modalId = ref('modal_' + Math.random().toString(36).substr(2, 9));
    

  
  const openModal = () => {
    const modalElement = document.getElementById(modalId.value);
    modal = new $bootstrap.Modal(modalElement);
    state = "open";
    modal.show();
  };
  
  const create = () => {
    if (typeof props.onCreate === 'function') {
        props.onCreate();
    }
  }

  
  const custom = () => {
    if (typeof props.onCustomButtonClick === 'function') {
        props.onCustomButtonClick();
    }
  }

  const add = () => {
    
    if (typeof props.onAdd === 'function') {
        props.onAdd();
    }
  }
  

  const update = () => {
    
    if (typeof props.onUpdate === 'function') {
        props.onUpdate();
    }
  }

  const remove = () => {
    if (typeof props.onDelete === 'function') {
        props.onDelete();
    }
  }
  const approve = () => {
    if (typeof props.onApprove === 'function') {
        props.onApprove();
    }
  }

  const next = () => {
    if (typeof props.onNext === 'function') {
        props.onNext();
    }
  }

  const back = () => {
    if (typeof props.onNext === 'function') {
        props.onBack();
    }
  }

  const close = () => {
    if (typeof props.onClose === 'function') {
        props.onClose();
    }
  }


  const cancel = () => {
    
    if (typeof props.onCancel === 'function') {
        props.onCancel();
    }
  }

  const closeModal = () => {
    
    if (state == "open"){
      modal.hide();
      state = "closed";
    }
    if (typeof props.onClosed === 'function') {
        props.onClosed();
    }
    
    // if (typeof props.onClose === 'function') {
    //     props.onClose(/* pass any data if needed */);
    // }
    
  };

  function getRandomId(prefix, length) {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomId = prefix || ''; // Initialize with prefix if provided

    for (let i = 0; i < length; i++) {
      randomId += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return randomId;
  }
  
  defineExpose( {
    modal,
    openModal,
    closeModal
  });

  </script>
<style>
/* .modal-dialog-custom-width {
    width: 1200px
} */

.modal {
    --bs-modal-width: 800px;
}
</style>